import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/firebase-database";
import "firebase/auth";
import "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyDjMArD-xyzR5zn_Gb2eFBGVl6E54Lsu-4",
  authDomain: "allder-serwiz.firebaseapp.com",
  projectId: "allder-serwiz",
  storageBucket: "allder-serwiz.appspot.com",
  messagingSenderId: "509559142755",
  appId: "1:509559142755:web:5429ea8b33d63f7fe0bb56",
  measurementId: "G-NWRNSYNVQJ",
};
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const cfs = firebase.firestore();
const rdb = firebase.database();
const str = firebase.storage();

export { auth, cfs, rdb, str };

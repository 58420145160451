import React, { useState, useEffect } from "react";
import {
  Form,
  Spin,
  Card,
  Col,
  Row,
  Typography,
  DatePicker,
  ConfigProvider,
  Divider,
} from "antd";
import { IMerchant } from "../../models/merchant";
import { auth, cfs } from "../../firebase/firebase";
import { Navbar } from "../adminManagement/components";
import { G2, Chart, Tooltip, Interval, Legend, Interaction } from "bizcharts";
import moment from "moment";
import "moment/locale/th";
import locale from "antd/lib/locale/th_TH";
import { groupBy, numberWithCommas } from "../../utility";
import { IItemCart } from "../../models/ItemCart";

const { Title } = Typography;

const Report = ({ currentUser }: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [startDate, setStartDate] = useState<any>(
    moment().startOf("day").toDate()
  );
  const [endDate, setEndDate] = useState<any>(moment().endOf("day").toDate());
  const [successOrders, setSuccessOrders] = useState<Array<any>>([]);
  const [dataCategory, setDataCategory] = useState<Array<any>>([]);

  const onChangeDate = (dates: any, dateStrings: any) => {
    // console.log("From: ", dates[0], ", to: ", dates[1]);
    // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    if (dates && dates[0] && dates[1]) {
      // console.log('dates : ', dates)
      // console.log('dateStrings : ', dateStrings)
      let tmpStartDate = dates[0].startOf("day").toDate();
      let tmpEndDate = dates[1].endOf("day").toDate();
      // console.log('tmpStartDate : ', tmpStartDate)
      // console.log('tmpEndDate : ', tmpEndDate)
      setStartDate(tmpStartDate);
      setEndDate(tmpEndDate);

      // let tmpSaveDate = new Date();
      // localStorage.setItem("startDate", tmpStartDate);
      // localStorage.setItem("endDate", tmpEndDate);
      // localStorage.setItem("saveDate", tmpSaveDate.toString());
    } else {
      setError("วันที่ไม่ถูกต้อง");
    }
  };

  // useEffect(() => {
  //   let tmpStartDate = localStorage.getItem("startDate");
  //   let tmpEndDate = localStorage.getItem("endDate");
  //   let tmpSaveDate = localStorage.getItem("saveDate");

  //   let now = moment(new Date());
  //   if (tmpSaveDate) {
  //     let duration = moment.duration(moment(now).diff(new Date(tmpSaveDate)));
  //     let minutes = duration.minutes();
  //     console.log("minutes :>> ", minutes);
  //     if (minutes > 5 * 60) {
  //       tmpStartDate = moment(now).startOf("day").toString();
  //       tmpEndDate = moment(now).endOf("day").toString();
  //       localStorage.setItem("startDate", tmpStartDate);
  //       localStorage.setItem("endDate", tmpEndDate);
  //       localStorage.setItem("saveDate", now.toString());
  //     }
  //     // console.log("tmpStartDate :>> ", tmpStartDate);
  //     // console.log("tmpEndDate :>> ", tmpEndDate);
  //     if (tmpStartDate) {
  //       setStartDate(new Date(tmpStartDate));
  //     }
  //     if (tmpEndDate) {
  //       setEndDate(new Date(tmpEndDate));
  //     }
  //   }
  // }, []);

  useEffect(() => {
    document.title = `${currentUser.merchantNameTH} | ${currentUser.merchantNameEN}`;
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser.merchantId) return;
    setLoading(true);
    // console.log("x1 : ", startDate);
    // console.log("x2 : ", endDate);
    let unsubscribe = cfs
      .collection("/orders")
      .doc(`${currentUser.merchantId}`)
      .collection("myOrders")
      .where("status", "==", "success")
      .where("createDate", ">", startDate)
      .where("createDate", "<=", endDate)
      .onSnapshot({
        next: async (snapshot) => {
          if (snapshot.size) {
            let successOr = await Promise.all(
              snapshot.docs.map(async (i) => await i.data())
            );

            let myData: any = [];
            successOr.map((item) => {
              return item.items.map((item2: any) => {
                myData.push(item2);
                return item2;
              });
            });

            let dataGroup = groupBy(myData, "itemName");
            let categoryGroup = groupBy(myData, "category");
            let newData: any = Object.keys(dataGroup).map((itemName) => {
              let total = dataGroup[itemName].map((i: any) => {
                let price =
                  Number(i.price) +
                  (i.option === undefined
                    ? 0
                    : i.option.price === undefined
                    ? 0
                    : Number(i.option.price));

                return {
                  ...i,
                  total: i.amount * price,
                  pricePerPiece: price,
                  amount: i.amount,
                };
              });

              return {
                itemName: itemName,
                total: total.reduce((p: any, c: any) => p + c.total, 0),
                amount: total.reduce((p: any, c: any) => p + c.amount, 0),
              };
            });
            let newDataCategory: any = Object.keys(categoryGroup).map(
              (categoryName) => {
                let total = categoryGroup[categoryName].map((i: any) => {
                  let price =
                    Number(i.price) +
                    (i.option === undefined
                      ? 0
                      : i.option.price === undefined
                      ? 0
                      : Number(i.option.price));

                  return {
                    ...i,
                    total: i.amount * price,
                    pricePerPiece: price,
                    amount: i.amount,
                  };
                });

                return {
                  categoryName: categoryName,
                  total: total.reduce((p: any, c: any) => p + c.total, 0),
                  amount: total.reduce((p: any, c: any) => p + c.amount, 0),
                };
              }
            );
            setDataCategory(newDataCategory);
            setSuccessOrders(newData);
            setLoading(false);
          } else {
            // it's empty
            setDataCategory([]);
            setSuccessOrders([]);
            setLoading(false);
          }
        },
        error: (error) => {
          console.log(error);
          setError(error.message);
          setLoading(false);
        },
      });

    return () => {
      unsubscribe();
    };
  }, [currentUser.merchantId, startDate, endDate]);

  // console.log("successOrders", successOrders);

  return (
    <Spin tip="กำลังโหลดข้อมูล.." spinning={loading}>
      <div>
        <div className="site-card-wrapper" style={{ paddingBottom: 8 }}>
          <Row gutter={[16, 16]} align="middle">
            <Col>
              <Title level={5} style={{ margin: 0 }}>
                รายงานสรุปข้อมูลรวม
              </Title>
            </Col>
            <Col>
              <ConfigProvider locale={locale}>
                <DatePicker.RangePicker
                  // value={[moment(startDate,'DD-MM-YYYY'), moment(endDate, 'YYYY-MM-DD')]}
                  format="ll"
                  value={[moment(startDate), moment(endDate)]}
                  ranges={{
                    วันนี้: [moment(), moment()],
                    "3 วันที่แล้ว": [moment().subtract(3, "d"), moment()],
                    "5 วันที่แล้ว": [moment().subtract(5, "d"), moment()],
                    อาทิตย์นี้: [
                      moment().startOf("isoWeek"),
                      moment().endOf("isoWeek"),
                    ],
                    เดือนนี้: [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "3 เดือนที่แล้ว": [moment().subtract(3, "M"), moment()],
                    // '6 เดือนที่แล้ว': [moment().subtract(6, 'M'), moment()],
                    ปีที่แล้ว: [moment().subtract(1, "y"), moment()],
                  }}
                  onChange={onChangeDate}
                />
              </ConfigProvider>
            </Col>
          </Row>
          <Divider />
          {/* <Row gutter={[16, 16]}> */}
          <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24}>
              <Card
                title={<Title level={2}>รายได้รวม</Title>}
                bordered={false}
                hoverable={true}
                className="card-shadow"
              >
                <Row justify="space-between">
                  <Col>
                    <Title level={4}>รายได้รวม</Title>
                  </Col>
                  <Col>
                    <Title level={4}>{`${numberWithCommas(
                      successOrders.reduce((p, c) => p + c.total, 0)
                    )} บาท`}</Title>
                  </Col>
                </Row>
                {dataCategory.map((item, index) => {
                  return (
                    <Row justify="space-between" key={index}>
                      <Col>
                        <Title level={5}>{`${
                          item.categoryName
                        } (${numberWithCommas(item.amount)} รายการ)`}</Title>
                      </Col>
                      <Col>
                        <Title level={5}>{`${numberWithCommas(
                          item.total
                        )} บาท`}</Title>
                      </Col>
                    </Row>
                  );
                })}
              </Card>
            </Col>

            {successOrders
              .sort((a: any, b: any) => {
                if (a.itemName.toLowerCase() < b.itemName.toLowerCase()) {
                  return -1;
                }
                if (a.itemName.toLowerCase() > b.itemName.toLowerCase()) {
                  return 1;
                }
                return 0;
              })
              .map((item, index) => {
                return (
                  <Col xs={24} sm={24} md={8} lg={6} key={index}>
                    <Card
                      title={<Title level={2}>{item.itemName}</Title>}
                      bordered={false}
                      hoverable={true}
                      className="card-shadow"
                    >
                      <Row justify="space-between">
                        <Col>
                          <Title level={5}>จำนวน</Title>
                        </Col>
                        <Col>
                          <Title level={5}>{`${numberWithCommas(
                            item.amount
                          )} ออเดอร์`}</Title>
                        </Col>
                      </Row>
                      <Row justify="space-between">
                        <Col>
                          <Title level={4}>รายได้รวม</Title>
                        </Col>
                        <Col>
                          <Title level={4}>{`${numberWithCommas(
                            item.total
                          )} บาท`}</Title>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
    </Spin>
  );
};

export default Report;

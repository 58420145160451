import React, { useState, useEffect } from "react";

// ref : https://joshwcomeau.com/react/persisting-react-state-in-localstorage/
export const useLocalStorage = <T>(key: string, defaultValue: T) => {
  const [value, setValue] = useState<T>(() => {
    const storedValue = window.localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  });
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue] as const;
};

export const useCarts = <T>() => {
  const [value, setValue] = useState<T>(() => {
    const storedValue = window.localStorage.getItem("carts");
    return storedValue ? JSON.parse(storedValue) : [];
  });

  useEffect(() => {
    window.localStorage.setItem("carts", JSON.stringify(value));
  }, [value]);

  return [value, setValue] as const;
};
export const useQueue = () => {
  const [value, setValue] = useState(() => {
    const storedValue = window.localStorage.getItem("queue");
    return storedValue ? Number(storedValue) : 0;
  });

  useEffect(() => {
    window.localStorage.setItem("queue", `${value}`);
  }, [value]);

  return [value, setValue] as const;
};

export const clearCarts = () => {
  window.localStorage.removeItem("carts");
};

export const createMarkupWord = (word: string, serach: string) => {
  let newWord =
    serach.length > 0
      ? word.replace(
          new RegExp(serach, "gi"),
          (match) => `<mark>${match}</mark>`
        )
      : word;
  return { __html: newWord };
};

export const groupBy = (xs: Array<any>, key: string) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const validatePhone = (phone: string) => {
  const regExpNumber = /^(06!*|08!*|09!*)[0-9]{8}$|(\+666!*|\+668!*|\+669!*)[0-9]{8}$/;
  return regExpNumber.test(phone);
};

export const sortFunction = (a: any, b: any, name: any) => {
  let nameA = a[name] ? a[name] : "";
  let nameB = b[name] ? b[name] : "";
  return nameA === nameB ? 0 : nameA < nameB ? -1 : 1;
};

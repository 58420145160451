import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import {
  Form,
  Input,
  Button,
  notification,
  Spin,
  Select,
  Typography,
} from "antd";
import axios from "axios";
import "./customerRegister.css";
import firebase from "firebase/app";
import "firebase/firestore";
import { cfs } from "../../firebase/firebase";
import RegisterMap from "./registerMap";
import { useCarts, clearCarts } from "../../utility";
import { IItemCart } from "models/ItemCart";
import { IChoiceValue } from "models/choiceValue";
import ReactGA from "react-ga";
import { BASE_API_URL } from "../../config/index";

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;
interface ParamTypes {
  idMerchant: string;
  tableNumber: string;
}
const CustomerRegister = () => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const history = useHistory();

  const [error, setError] = useState("");
  const [customer, setCustomer] = useState({});
  const [center, setCenter] = useState({
    lat: 15.1173873,
    lng: 104.9019462,
  });
  const [form] = Form.useForm();
  const [carts, setCarts] = useCarts<Array<IItemCart>>();

  let { idMerchant } = useParams<ParamTypes>();

  const onFinish = async (values: any) => {
    console.log("onFinish:", values);
    let { address, name, phone } = values;
    let emptyValue = undefined;
    let updateData = {
      address: address ? address : emptyValue,
      name: name ? name : emptyValue,
      phone: phone ? phone : emptyValue,
      lat: center.lat,
      lng: center.lng,
    };
    setLoading(true);
    setDisabled(true);
    let readStatePrint = await (
      await cfs.collection("merchants").doc(`${idMerchant}`).get()
    ).data();
    console.log("carts", carts);
    let query = cfs
      .collection("/orders")
      .doc(`${idMerchant}`)
      .collection("myOrders");
    let autoID = query.doc().id;
    query
      .doc(autoID)
      .set({
        orderId: autoID,
        items: carts.map((i) => ({ ...i, docId: autoID })),
        total: carts
          .map((i) => ({ ...i }))
          .reduce((pre: number, crr: IItemCart) => {
            return (crr.price + crr.totalOption) * crr.amount + pre;
          }, 0),
        contact: updateData,
        status: { ...readStatePrint }.autoPrint ? "new" : "pendingNew",
        queue: 0,
        createDate: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then((d) => {
        console.log("success");
        ReactGA.event({
          category: "Register",
          action: "Register Success",
          label: `Register Success with ${idMerchant} orderId ${autoID}`,
        });
        notificationMessage(
          `Register Success with ${idMerchant} orderId ${autoID} \n\n${JSON.stringify(
            carts
          )} \n\n${JSON.stringify(updateData)}`
        );
        clearCarts();
        history.push(`/${idMerchant}/complete/${autoID}`);
      })
      .catch((e) => {
        console.log(e);
        ReactGA.exception({
          description: `Register : ${e}`,
          fatal: true,
        });
      });
  };

  const notificationMessage = (message: string) => {
    return axios
      .post(
        `${BASE_API_URL}/line`,
        { message: message },
        {
          headers: {
            "content-type": `application/json`,
          },
        }
      )
      .then((response) => {
        console.log("line notify  response : ", response.data);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.log("line notify error : ", error.message);
        return Promise.resolve({ message: error.message, status: "fail" });
      });
  };

  useEffect(() => {
    console.log("error :>> ", error);
    if (error) {
      notification.error({
        message: "เกิดข้อผิดพลาด",
        description: error,
        placement: "bottomRight",
        onClick: () => {
          setError("");
        },
        onClose: () => {
          setError("");
        },
      });
    }
  }, [error]);

  const onFinishFailed = (errorInfo: any) => {
    console.log("onFinishFailed:", errorInfo);
  };

  return (
    <Spin spinning={loading}>
      <Form
        style={{ padding: "8px 8px 8px 8px" }}
        layout={"vertical"}
        form={form}
        onFinish={onFinish}
        size={"large"}
      >
        <div style={{ marginBottom: 8 }}>
          <div style={{ position: "relative", height: "50vh" }}>
            <RegisterMap center={center} setCenter={setCenter} />
          </div>
          <Text type="secondary">
            {`ละติจูด: ${center.lat}, ลองจิจูด: ${center.lng}`}
          </Text>
        </div>

        <Form.Item label="ชื่อ" name="name" rules={[{ required: true }]}>
          <Input placeholder="เช่น ทานตะวัน" />
        </Form.Item>
        <Form.Item
          label="โทรศัพท์มือถือ"
          name="phone"
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  /^(06!*|08!*|09!*)[0-9]{8}$|(\+666!*|\+668!*|\+669!*)[0-9]{8}$/.test(
                    getFieldValue("phone")
                  )
                ) {
                  return Promise.resolve();
                }
                return Promise.reject("เบอร์โทรศัพท์ไม่ถูกต้อง");
              },
            }),
          ]}
        >
          <Input placeholder="0811234567" />
        </Form.Item>
        <Form.Item
          label="ที่อยู่เพิ่มเติม"
          name="address"
          rules={[{ required: true }]}
        >
          <TextArea
            rows={4}
            placeholder="ส่งใต้ตึกหอพักท็อปวัน โซนหน้าเคาน์เตอร์"
          />
        </Form.Item>
        <Form.Item>
          <Button
            style={{ width: "100%", height: "50px" }}
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={disabled}
          >
            ดำเนินการต่อ
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default CustomerRegister;

import React, { useState, useRef } from "react";
import {
  Form,
  Input,
  Radio,
  Switch,
  Modal,
  Transfer,
  Divider,
  notification,
  Button,
} from "antd";

import { cfs } from "../../../../firebase/firebase";
import { SmileOutlined } from "@ant-design/icons";

const CreateChoiceGroupModal = ({
  visible,
  setVisible,
  currentUser,
  choices,
}: any) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const onFormFinish = (value: any) => {
    console.log(value);
    setConfirmLoading(true);
    let query = cfs
      .collection("merchants")
      .doc(currentUser.merchantId)
      .collection("choiceGroups");

    let queryChoice = cfs
      .collection("merchants")
      .doc(currentUser.merchantId)
      .collection("choices");
    let autoId = query.doc().id;
    query
      .doc(autoId)
      .set({
        ...value,
        id: autoId,
        choices: targetKeys.map((i) => queryChoice.doc(i)),
      })
      .then((d) => {
        console.log(d);
        openNotification("สำเร็จ", "สร้างกลุ่มตัวเลือกสำเร็จแล้ว");
        setVisible(false);
        setConfirmLoading(false);
      })
      .catch((e) => {
        console.log(e);
        openNotification(
          "เกิดข้อผิดพลาด",
          "ไม่สามรถสร้างรายการกลุ่มตัวเลือกได้"
        );
        setVisible(false);
        setConfirmLoading(false);
      });
  };

  const openNotification = (title: string, discription: string) => {
    notification.open({
      message: title,
      description: discription,
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const handleChange = (nextTargetKeys: any, direction: any, moveKeys: any) => {
    setTargetKeys(nextTargetKeys);
  };

  const handleSelectChange = (
    sourceSelectedKeys: any,
    targetSelectedKeys: any
  ) => {
    let tmp: any = [...targetSelectedKeys, ...sourceSelectedKeys];
    setSelectedKeys(tmp);
  };

  console.log("targetKeys", targetKeys);
  console.log("selectedKeys", selectedKeys);

  return (
    <Modal
      title="เพิ่มตัวเลือกเสริม"
      visible={visible}
      // onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      width={500}
      footer={[
        <Button htmlType="submit" key="cancel" onClick={() => handleCancel()}>
          ยกเลิก
        </Button>,
        <Button
          htmlType="submit"
          type="primary"
          key="submit"
          disabled={targetKeys.length === 0}
          onClick={handleOk}
        >
          ตกลง
        </Button>,
      ]}
    >
      <div>
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          layout="horizontal"
          initialValues={{ price: 0, active: true, type: "radio" }}
          form={form}
          onFinish={onFormFinish}
          size={"middle"}
        >
          <Form.Item
            label="ชื่อ"
            name="choiceName"
            rules={[
              {
                required: true,
                message: "กรุณากรอกชื่อ",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="type" label="รูปแบบการเลือก">
            <Radio.Group>
              <Radio value="radio">ตัวเลือกเดียว</Radio>
              <Radio value="checkbox">หลายตัวเลือก</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="เปิดใช้งาน" name="active" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
        <Divider />
        <Transfer
          showSearch
          listStyle={{
            width: 250,
            height: 240,
          }}
          dataSource={choices}
          titles={["ตัวเลือก", "ที่เลือก"]}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={handleChange}
          onSelectChange={handleSelectChange}
          render={(item) => item.choiceName}
          rowKey={(record) => record.id}
          oneWay
          style={{ marginBottom: 16 }}
        />
      </div>
    </Modal>
  );
};
export default CreateChoiceGroupModal;

import React, { useState, useEffect } from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom";
interface ParamTypes {
  idMerchant: string;
  orderId: string;
}
function CompleteOrder() {
  let { idMerchant, orderId } = useParams<ParamTypes>();
  const history = useHistory();

  return (
    <Result
      status="success"
      title="ส่งคำสั่งแล้ว ขอบคุณที่ใช้บริการ"
      subTitle={`หมายเลขติดตาม: ${orderId} เจ้าหน้าที่จะติต่อกลับคุณเร็วๆนี้`}
      extra={[
        <Button
          onClick={() => {
            history.push(`/${idMerchant}`);
          }}
        >
          กลับหน้าหลัก
        </Button>,
      ]}
    />
  );
}

export default CompleteOrder;

import React, { useState, useRef } from "react";
import {
  Modal,
  Card,
  Button,
  List,
  message,
  Avatar,
  Spin,
  Row,
  Col,
  Descriptions,
  Divider,
  Typography,
} from "antd";
import { IEmployee } from "models/employee";
import { QRCode } from "react-qrcode-logo";
import { IOrder } from "models/order";
import { IChoiceValue } from "models/choiceValue";
import { IItemCart } from "models/ItemCart";
import { CopyOutlined } from "@ant-design/icons";
import { groupBy, numberWithCommas } from "utility";
import "./modalSelectEmployee.css";

const { Title, Text } = Typography;

type ModalSelectEmployeeProps = {
  open: boolean;
  employees: IEmployee[];
  order: IOrder;
  confirmButton: boolean;
  confirmButtonLoading: boolean;
  currentUser: any;
  handleCancel: () => void;
  downloadQR: () => void;
  handleSelectEmployee: (emp: IEmployee) => void;
};
const ModalSelectEmployee = ({
  open,
  employees,
  order,
  confirmButton,
  confirmButtonLoading,
  currentUser,
  handleCancel,
  downloadQR,
  handleSelectEmployee,
}: ModalSelectEmployeeProps) => {
  return (
    <Modal
      title="เปิด"
      centered
      visible={open}
      onCancel={() => handleCancel()}
      width={800}
      footer={[
        // <Button htmlType="submit" key="cancel" onClick={() => handleCancel()}>
        //   ยกเลิก
        // </Button>,
        <Button
          htmlType="submit"
          type="primary"
          key="submit"
          loading={confirmButtonLoading}
          disabled={!confirmButton}
          onClick={() => {
            downloadQR();
          }}
        >
          ตกลง
        </Button>,
      ]}
    >
      <Card bordered={false}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12}>
            <List
              dataSource={employees}
              renderItem={(item) => (
                <List.Item key={item.employeeId}>
                  {item.imageUrl ? (
                    <List.Item.Meta
                      avatar={<Avatar src={item.imageUrl} />}
                      title={<a>{`${item.firstname} ${item.lastname}`}</a>}
                      description={item.phone}
                    />
                  ) : (
                    <List.Item.Meta
                      title={<a>{`${item.firstname} ${item.lastname}`}</a>}
                      description={item.phone}
                    />
                  )}

                  <div>
                    <Button
                      onClick={() => {
                        handleSelectEmployee(item);
                      }}
                    >
                      เลือก
                    </Button>
                  </div>
                </List.Item>
              )}
            ></List>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <div
              id="qrprint"
              // className="text-block"
              style={{
                minWidth: "100%",
                textAlign: "center",
                padding: "8px",
                backgroundColor: "white",
                // fontFamily: "monospace",
              }}
            >
              <Descriptions
                title="งาน"
                column={1}
                layout={"horizontal"}
                size={"small"}
              >
                <Descriptions.Item label="ชื่อ">
                  {`${order["contact"]["name"]}`}
                </Descriptions.Item>
                <Descriptions.Item label="ที่อยู่">
                  {`${order["contact"]["address"]}`}
                </Descriptions.Item>
                <Descriptions.Item label="ตำแหน่ง">
                  <Row justify="space-between">
                    <Col>
                      <a
                        type="link"
                        href={`https://www.google.com/maps/search/?api=1&query=${order["contact"]["lat"]},${order["contact"]["lng"]}`}
                        target="_blank"
                      >{`${Number(order["contact"]["lat"]).toFixed(6)},${Number(order["contact"]["lng"]).toFixed(6)}`}</a>
                    </Col>
                    <Col>
                      <a style={{ marginLeft: "10px" }}>
                        <CopyOutlined />
                      </a>
                    </Col>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label="โทรศัพท์">
                  {`${order["contact"]["phone"]}`}
                </Descriptions.Item>
                <Descriptions.Item label="ช่าง">
                  {order.employee
                    ? `${order.employee.firstname} ${order.employee.lastname} (${order.employee.phone})`
                    : null}
                </Descriptions.Item>
              </Descriptions>
              <Divider style={{ width: "100%" }} />
              <Row>
                <Col>
                  <Title level={5}>รายการ</Title>{" "}
                </Col>
              </Row>
              {/* <Divider orientation="left">sub-element align left</Divider> */}

              {order["items"].map((res: IItemCart, index: number) => (
                <Row justify="space-between" key={`Row-${index}`}>
                  <Col>
                    <Text
                    // level={5}
                    >{`${res.amount}x ${res.itemName} (${res.price})`}</Text>
                    <p style={{ color: "gray",textAlign : 'left' }}>
                      {res.options.map(
                        (option: IChoiceValue) =>
                          `${option.name}(${option.price})`
                      )}
                      {res.note !== "" ? `*${res.note}` : ""}
                    </p>
                  </Col>
                  <Col>
                    {" "}
                    <Text
                      type="secondary"
                      // level={5}
                    >{`${(res.price + res.totalOption) * res.amount}฿`}</Text>
                  </Col>
                </Row>
              ))}
              <Divider style={{ width: "100%" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // marginBottom: 10,
                  fontSize: 16,
                  fontWeight: 900,
                }}
              >
                <Typography style={{ color: "#000" }}>รวมทั้งหมด</Typography>
                <Typography style={{ color: "#000" }}>{`฿${numberWithCommas(
                  order["total"]
                )}`}</Typography>
              </div>

              <QRCode
                value={
                  order.employee
                    ? `http://serwiz.net/${currentUser.merchantId}/employee/${order.employee.employeeId}`
                    : ""
                }
                // size={}
                // size={250}
                ecLevel="M"
                // logoImage={ThaiQRpaymentsMini}
              />
              <div>
                <Text>
                  <strong>ให้ลูกค้าสแกนเพื่อตรวจสอบข้อมูล</strong>
                </Text>{" "}
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </Modal>
  );
};

export default ModalSelectEmployee;

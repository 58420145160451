import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Checkbox,
  Input,
  Modal,
  InputNumber,
  AutoComplete,
  Spin,
  notification,
  Select,
  Radio,
  Space,
  Row,
  Col,
} from "antd";
import { cfs } from "../../../../firebase/firebase";
import firebase from "firebase/app";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const { Option } = Select;
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

const ModalAddProduct = ({ open, handleAddProduct }) => {
  const [form] = Form.useForm();
  const [optionsCatagory, setOptionsCatagory] = useState([]);
  const [optionsUnit, setOptionsUnit] = useState([]);
  const [optionsUnitSale, setOptionsUnitSale] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [typePrice, setTypePrice] = useState("normal");
  const [option, setCheckNick] = useState(false);
  const onFinish = async (values) => {
    console.log("Success:", values);
    values = values.unitSale
      ? {
          ...values,

          unitSale: values.unitSale.map((item) => ({
            ...item,
            unitPrice: Number(item.unitPrice),
            hasInput: item.hasInput === undefined ? false : item.hasInput,
          })),
        }
      : values;
    values = values.options
      ? {
          ...values,
          options: values.options.map((item) => ({
            ...item,
            optionPrice: Number(item.optionPrice),
          })),
        }
      : values;
    values = {
      ...values,
      price: Number(values.price),
    };
    setLoading(true);
    setDisable(true);
    console.log(values);
    form.resetFields();
    try {
      let query = cfs.collection("products");
      let getvalue = await query.orderBy("createDate", "desc").limit(1).get();
      if (getvalue.size > 0) {
        let lastId = getvalue.docs[0].data().productId;
        let newId =
          `P` + `${Number(lastId.substring(1, 6)) + 1}`.padStart(5, "0");
        cfs
          .collection("products")
          .doc(newId)
          .set({
            ...values,
            typePrice: typePrice === undefined ? "normal" : typePrice,
            productId: newId,
            createDate: firebase.firestore.Timestamp.fromDate(new Date()),
            updateDate: firebase.firestore.Timestamp.fromDate(new Date()),
          })
          .then((doc) => {
            handleClose();
            console.log(doc);
            openNotificationWithIcon(
              "success",
              "สำเร็จ",
              "เพิ่มสินค้าใหม่สำเร็จ"
            );
          })
          .catch((e) => {
            console.log(e);
            openNotificationWithIcon(
              "error",
              "เกิดข้อผิดพลาด",
              "ไม่สามารถเพิ่มสินค้าใหม่ได้"
            );
          });
      } else {
        let newId = `P00001`;
        cfs
          .collection("products")
          .doc(newId)
          .set({
            ...values,
            typePrice: typePrice === undefined ? "normal" : typePrice,
            productId: newId,
            createDate: firebase.firestore.Timestamp.fromDate(new Date()),
            updateDate: firebase.firestore.Timestamp.fromDate(new Date()),
          })
          .then((doc) => {
            handleClose();
            console.log(doc);
            openNotificationWithIcon(
              "success",
              "สำเร็จ",
              "เพิ่มสินค้าใหม่สำเร็จ"
            );
          })
          .catch((e) => {
            console.log(e);
            openNotificationWithIcon(
              "error",
              "เกิดข้อผิดพลาด",
              "ไม่สามารถเพิ่มสินค้าใหม่ได้"
            );
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateForm = () => {
    console.log("validateForm");
    form.submit();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  const onSearchCatagory = (searchText) => {
    let tmpOptions = [
      {
        value: "ท่อ",
      },
      {
        value: "ปอก",
      },
      {
        value: "เสา",
      },
      {
        value: "อิฐ",
      },
    ];
    setOptionsCatagory(tmpOptions.filter((i) => i.value.includes(searchText)));
  };

  const onSelectCatagory = (data) => {
    console.log("onSelect", data);
  };

  const onSearchUnit = (searchText) => {
    let tmpOptions = [
      {
        value: "อัน",
      },
      {
        value: "ชิ้น",
      },
      {
        value: "เมตร",
      },
      {
        value: "ก้อน",
      },
    ];
    setOptionsUnit(tmpOptions.filter((i) => i.value.includes(searchText)));
  };

  const onSelectUnit = (data) => {
    console.log("onSelect", data);
  };

  const handleClose = () => {
    form.resetFields();
    setLoading(false);
    setDisable(false);
    handleAddProduct(false);
  };

  const openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
      placement: "bottomRight",
    });
  };

  const onChangeTypePrice = (e) => {
    setTypePrice(e.target.value);
  };
  const onCheckOption = (e) => {
    setCheckNick(e.target.checked);
  };

  return (
    <Modal
      title="เพิ่มสินค้า"
      centered
      visible={open}
      onOk={validateForm}
      onCancel={handleClose}
      okButtonProps={{ disabled: disable }}
      cancelButtonProps={{ disabled: disable }}
    >
      <Spin spinning={loading}>
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="ชื่อสินค้า"
            name="productName"
            rules={[{ required: true, message: "กรุณากรอกข้อมูล ชื่อสินค้า" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="หมวดหมู่"
            name="catagory"
            rules={[{ required: true, message: "กรุณากรอกข้อมูล หมวดหมู่" }]}
          >
            <AutoComplete
              options={optionsCatagory}
              style={{
                width: 200,
              }}
              onSelect={onSelectCatagory}
              onSearch={onSearchCatagory}
            />
          </Form.Item>

          <Form.Item
            label="หน่วยเรียก"
            name="unit"
            rules={[{ required: true, message: "กรุณากรอกข้อมูล หมวดหมู่" }]}
          >
            <AutoComplete
              options={optionsUnit}
              style={{
                width: 200,
              }}
              onSelect={onSelectUnit}
              onSearch={onSearchUnit}
            />
          </Form.Item>

          <Form.Item
            label="ราคาเริ่มต้น"
            name="price"
            rules={[{ required: true, message: "กรุณากรอกข้อมูล ราคา" }]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item label="รูปแบบ" name="type">
            <Checkbox checked={option} onChange={onCheckOption}></Checkbox>
          </Form.Item>
          <Form.Item label="รายการท๊อปปิ้ง" name="topping">
            <Checkbox checked={option} onChange={onCheckOption}></Checkbox>
          </Form.Item>

          <Form.Item name="typePrice" label="รูปแบบ">
            <Radio.Group
              defaultValue={typePrice}
              value={typePrice}
              onChange={(e) => onChangeTypePrice(e)}
            >
              <Radio value="normal">ไม่มี</Radio>
              <Radio value="spacial">มี</Radio>
            </Radio.Group>
          </Form.Item>

          {typePrice === "normal" ? (
            <div></div>
          ) : (
            <Form.List name="unitSale">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, index) => (
                      <div>
                        <Row key={index}>
                          <Col span="11">
                            <Form.Item
                              labelCol={{ span: 11 }}
                              wrapperCol={{ span: 13 }}
                              noStyle
                              shouldUpdate={(prevValues, curValues) =>
                                prevValues.unitPrice !== curValues.unitPrice ||
                                prevValues.unitName !== curValues.unitName
                              }
                            >
                              {() => (
                                <Form.Item
                                  {...field}
                                  labelCol={{ span: 11 }}
                                  wrapperCol={{ span: 13 }}
                                  label="ชื่อ"
                                  labelPosition="top"
                                  name={[field.name, "name"]}
                                  fieldKey={[field.fieldKey, "name"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "ระบุชื่อ",
                                    },
                                  ]}
                                >
                                  <AutoComplete
                                    options={optionsUnit}
                                    onSelect={onSelectUnit}
                                    onSearch={onSearchUnit}
                                  />
                                </Form.Item>
                              )}
                            </Form.Item>
                          </Col>

                          <Col span="11">
                            <Form.Item
                              {...field}
                              labelCol={{ span: 11 }}
                              wrapperCol={{ span: 12 }}
                              label="ราคา"
                              name={[field.name, "price"]}
                              fieldKey={[field.fieldKey, "price"]}
                              rules={[{ required: true, message: "ระบุราคา" }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col span="2">
                            <MinusCircleOutlined
                              onClick={() => remove(field.name)}
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}

                    <Form.Item
                      labelCol={{
                        span: 4,
                      }}
                      wrapperCol={{
                        span: 9,
                        offset: 5,
                      }}
                    >
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                      >
                        <PlusOutlined /> เพิ่มรูปแบบ
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          )}

          <Form.Item
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 9,
              offset: 5,
            }}
          >
            <Checkbox checked={option} onChange={onCheckOption}>
              เพิ่ม Topping
            </Checkbox>
          </Form.Item>

          {option && (
            <Form.List name="options">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field) => (
                      <Row>
                        <Col span="11">
                          <Form.Item
                            labelCol={{ span: 11 }}
                            wrapperCol={{ span: 13 }}
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.unitPrice !== curValues.unitPrice ||
                              prevValues.optionName !== curValues.optionName
                            }
                          >
                            {() => (
                              <Form.Item
                                {...field}
                                labelCol={{ span: 11 }}
                                wrapperCol={{ span: 13 }}
                                label="ชื่อ"
                                labelPosition="top"
                                name={[field.name, "optionName"]}
                                fieldKey={[field.fieldKey, "optionName"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "ระบุชื่อ",
                                  },
                                ]}
                              >
                                <AutoComplete
                                  options={optionsUnit}
                                  onSelect={onSelectUnit}
                                  onSearch={onSearchUnit}
                                />
                              </Form.Item>
                            )}
                          </Form.Item>
                        </Col>

                        <Col span="11">
                          <Form.Item
                            {...field}
                            labelCol={{ span: 11 }}
                            wrapperCol={{ span: 12 }}
                            label="ราคา"
                            name={[field.name, "optionPrice"]}
                            fieldKey={[field.fieldKey, "optionPrice"]}
                            rules={[{ required: true, message: "ระบุราคา" }]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span="2">
                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </Col>
                      </Row>
                    ))}

                    <Form.Item
                      labelCol={{
                        span: 4,
                      }}
                      wrapperCol={{
                        span: 9,
                        offset: 5,
                      }}
                    >
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                      >
                        <PlusOutlined /> เพิ่มตัวเลือก
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalAddProduct;

import React, { useState } from "react";
import { Form, Input, InputNumber, Switch, Modal, notification } from "antd";
import { cfs } from "../../../../firebase/firebase";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";

const AddChoiceModal = ({ visible, setVisible, currentUser }: any) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  const onFormFinish = (value: any) => {
    console.log(value);
    setConfirmLoading(true);
    let query = cfs
      .collection("merchants")
      .doc(currentUser.merchantId)
      .collection("choices");
    let autoId = query.doc().id;
    query
      .doc(autoId)
      .set({
        ...value,
        id: autoId,
      })
      .then((d) => {
        console.log(d);
        setVisible(false);
        openNotification("success", "สำเร็จ", "สร้างกลุ่มตัวเลือกสำเร็จแล้ว");
        setConfirmLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setVisible(false);
        openNotification(
          "fail",
          "ล้มเหลว",
          "สร้างกลุ่มตัวเลือกไม่สำเร็จสำเร็จ"
        );
        setConfirmLoading(false);
      });
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const openNotification = (
    type: string,
    title: string,
    discription: string
  ) => {
    notification.open({
      message: title,
      description: discription,
      icon:
        type === "success" ? (
          <SmileOutlined style={{ color: "#108ee9" }} />
        ) : (
          <FrownOutlined style={{ color: "#ff4d4f" }} />
        ),
    });
  };

  return (
    <Modal
      title="เพิ่มตัวเลือกเสริม"
      visible={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      width={400}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 13 }}
        layout="horizontal"
        // onValuesChange={onFormLayoutChange}
        initialValues={{ price: 0, active: true }}
        form={form}
        onFinish={onFormFinish}
        size={"middle"}
      >
        <Form.Item
          label="ชื่อ"
          name="choiceName"
          rules={[
            {
              required: true,
              message: "กรุณากรอกชื่อ",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="ราคา" name="price">
          <InputNumber />
        </Form.Item>
        <Form.Item label="เปิดใช้งาน" name="active" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddChoiceModal;

import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Typography,
  Row,
  Col,
  Card,
  Divider,
  Switch,
  Button,
  Modal,
  Spin,
  DatePicker,
  notification,
  ConfigProvider,
  Empty,
  Descriptions,
  Tooltip,
} from "antd";
import "./adminManagement.css";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";
import {
  CloseOutlined,
  CheckOutlined,
  PrinterOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import "moment/locale/th";
import locale from "antd/lib/locale/th_TH";
import { ModalAddItem, ModalSelectEmployee } from "./components";
import firebase from "firebase/app";
import { cfs } from "../../firebase/firebase";
import { IItemCart, ItemCart } from "../../models/ItemCart";
import { IMerchant } from "../../models/merchant";
import { IItem } from "../../models/Item";

import { groupBy, numberWithCommas } from "../../utility";
import { IChoiceValue } from "../../models/choiceValue";
import { IEmployee } from "models/employee";
import { IOrder } from "models/order";
// import ModalPrint from "./components/modalPrint";
const { confirm } = Modal;
const { Title, Text } = Typography;

const AdminManagement = ({ currentUser }: any) => {
  const [products, setProducts] = useState<object>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [confirmButton, setConfirmButton] = useState<boolean>(false);
  const [confirmButtonLoading, setConfirmButtonLoading] = useState<boolean>(
    false
  );
  const [
    openModalSelectEmployee,
    setOpenModalSelectEmployee,
  ] = useState<boolean>(false);
  const [disablePrintButton, setDisablePrintButton] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [checked, setChecked] = useState<string>("Auto");
  const [checkedState, setCheckedState] = useState<boolean>(false);
  const [newOrders, setNewOrders] = useState<IOrder[]>([]);
  const [pendingOrders, setPendingOrders] = useState<IOrder[]>([]);

  const [successOrders, setSuccessOrders] = useState<IOrder[]>([]);
  const [productsCart, setProductsCart] = useState<Array<IItemCart>>([]);
  const [startDate, setStartDate] = useState<any>(
    moment().startOf("day").toDate()
  );
  const [endDate, setEndDate] = useState<any>(moment().endOf("day").toDate());
  const [search, setSearch] = useState<string>("");
  const [width, setWidth] = useState<number>(0);
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [tmpOrder, setTmpOrder] = useState<IOrder>({
    orderId: "",
    contact: {
      address: "",
      lat: 0,
      lng: 0,
      name: "",
      phone: "",
    },
    status: "",
    createDate: firebase.firestore.Timestamp.now(),
    items: [],
    employee: {
      employeeId: "",
      firstname: "",
      lastname: "",
      description: "",
      phone: "",
      imageUrl: "",
    },
  });

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    if (currentUser.merchantNameTH) {
      // console.log("currentUser :>> ", currentUser);
      document.title = `${currentUser.merchantNameTH} | ${currentUser.merchantNameEN}`;
      setCheckedState(currentUser.autoPrint);
      setLoading(false);
    }
  }, [currentUser.merchantNameTH]);

  const [dataItem, setDataItem] = useState<IItem>({
    productId: "",
    itemName: "",
    price: 0,
    category: "",
    imageUrl: "",
    options: [],
    choices: [],
  });

  const [visibleModalOption, setVisibleModalOption] = useState<boolean>(false);

  const createOrder = async (tableNumber: string) => {
    console.log("createOrder", productsCart);
    let query = cfs
      .collection("/orders")
      .doc(`${currentUser.merchantId}`)
      .collection("myOrders");
    let autoID = query.doc().id;
    query
      .doc(autoID)
      .set({
        id: autoID,
        items: productsCart,
        status: "new",
        table: Number(tableNumber),
        queue: 0,
        createDate: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then((d) => {
        console.log("success");
        setProductsCart([]);
        setOpenModalAddItemToBill(false);
      })
      .catch((e) => {
        console.log(e);
        setError(e.message);
      });
  };

  const addItemCart = (item: IItemCart) => {
    console.log(`addItemCart`, item);
    setProductsCart((items: Array<IItemCart>) => [...items, item]);
    setVisibleModalOption(false);
  };

  useEffect(() => {
    loadProducts();
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({
        message: "เกิดข้อผิดพลาด",
        description: error,
        placement: "bottomRight",
        onClick: () => {
          setError("");
        },
        onClose: () => {
          setError("");
        },
      });
    }
  }, [error]);

  const loadProducts = async () => {
    if (currentUser.merchantId != "")
      cfs
        .collection("merchants")
        .doc(`${currentUser.merchantId}`)
        .collection("products")
        .get()
        .then((doc) => {
          let pros = doc.docs.map((i) => i.data());

          setProducts(groupBy(pros, "category"));
          // setProducts(pros);
        })
        .catch((e) => {
          console.log(e);
          setError(e.message);
        });
  };

  useEffect(() => {
    let unsubscribe: any;
    if (currentUser.merchantId) {
      unsubscribe = cfs
        .collection("/merchants")
        .doc(`${currentUser.merchantId}`)
        .collection("employees")
        .onSnapshot({
          next: async (snapshot) => {
            if (snapshot.size) {
              let tmpemployees: any = await Promise.all(
                snapshot.docs.map(async (item) => {
                  return { ...item.data(), id: item.id };
                })
              );

              tmpemployees = tmpemployees.map((item: any, index: number) => ({
                ...item,
                no: index + 1,
              }));
              // console.log("tmpemployees :>> ", tmpemployees);
              setEmployees(tmpemployees);
              setLoading(false);
            } else {
              // it's empty
              setEmployees([]);
              setLoading(false);
            }
          },
          error: (error) => {
            console.log(error);
            setError(error.message);
            setLoading(false);
          },
        });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser.merchantId]);

  useEffect(() => {
    if (!currentUser.merchantId) return;
    let unsubscribe = cfs
      .collection("/orders")
      .doc(`${currentUser.merchantId}`)
      .collection("myOrders")
      .where("status", "in", ["new", "pendingNew"])
      .onSnapshot({
        next: async (snapshot) => {
          // console.log(snapshot.size);
          if (snapshot.size) {
            let newOr: any = await Promise.all(
              snapshot.docs.map((i) => i.data())
            );

            setNewOrders(newOr);
          } else {
            // it's empty
            setNewOrders([]);
          }
        },
        error: (error) => {
          console.log(error);
          setError(error.message);
          setLoading(false);
        },
      });

    return () => {
      unsubscribe();
    };
  }, [currentUser.merchantId]);

  useEffect(() => {
    if (!currentUser.merchantId) return;
    let unsubscribe = cfs
      .collection("/orders")
      .doc(`${currentUser.merchantId}`)
      .collection("myOrders")
      .where("status", "in", ["pending", "pendingPrint"])
      .onSnapshot({
        next: async (snapshot) => {
          if (snapshot.size) {
            let pendingOr: any = await Promise.all(
              snapshot.docs.map((i) => i.data())
            );

            setPendingOrders(pendingOr);
          } else {
            // it's empty
            setPendingOrders([]);
          }
        },
        error: (error) => {
          console.log(error);
          setError(error.message);
          setLoading(false);
        },
      });

    return () => {
      unsubscribe();
    };
  }, [currentUser.merchantId]);

  useEffect(() => {
    if (!currentUser.merchantId) return;

    setLoading(true);
    // console.log("x1 : ", startDate);
    // console.log("x2 : ", endDate);
    let unsubscribe = cfs
      .collection("/orders")
      .doc(`${currentUser.merchantId}`)
      .collection("myOrders")
      // .where("status", "==", "success")
      .where("status", "in", ["success", "cancel"])
      .where("createDate", ">", startDate)
      .where("createDate", "<=", endDate)
      .onSnapshot({
        next: async (snapshot) => {
          if (snapshot.size) {
            let successOr: any = await Promise.all(
              snapshot.docs.map((i) => i.data())
            );

            // console.log("nai : ", successOr.length);

            setSuccessOrders(successOr);
            setLoading(false);
          } else {
            // it's empty
            setSuccessOrders([]);
            setLoading(false);
          }
        },
        error: (error) => {
          console.log(error);
          setError(error.message);
          setLoading(false);
        },
      });

    return () => {
      unsubscribe();
    };
  }, [currentUser.merchantId, startDate, endDate]);

  const customModal = (data: IItem) => {
    setVisibleModalOption(true);
    setDataItem(data);
  };

  const removeItemFromBill = (item: IItemCart) => {
    console.log("item", item);
    console.log("itemId : ", item["id"]);
    console.log("itemDocId : ", item["docId"]);

    const doc: any = pendingOrders.find((i: any) => i.id === item["docId"]);

    console.log("doc : ", doc);

    let newItem = doc.items.filter((i: any) => i["id"] !== item["id"]);
    console.log("newItem : ", newItem);

    let query = cfs
      .collection("orders")
      .doc(`${currentUser.merchantId}`)
      .collection("myOrders")
      .doc(`${item["docId"]}`);

    if (newItem.length > 0) {
      query
        .update({
          items: newItem,
        })
        .then((i) => console.log(i))
        .catch((e) => {
          console.log(e);
          setError(e.message);
        });
    } else {
      query
        .delete()
        .then((i) => console.log(i))
        .catch((e) => {
          console.log(e);
          setError(e.message);
        });
    }
  };

  const onChange = async (checked: boolean) => {
    if (checked === true) {
      setChecked("Auto");
      setCheckedState(true);
      cfs
        .collection("/merchants")
        .doc(`${currentUser.merchantId}`)
        .update({ autoPrint: true })
        .then((d) => {
          cfs
            .collection("/orders")
            .doc(`${currentUser.merchantId}`)
            .collection("/myOrders")
            .where("status", "in", ["new", "pendingNew"])
            .get()
            .then(async (orders) => {
              let tmpOrders = await Promise.all(
                orders.docs.map(async (item: any) => await { ...item.data() })
              );
              console.log("set autoPrint: true");
              console.log("tmpOrders", tmpOrders);
              tmpOrders.map((item: IOrder) => {
                print(item);
              });
            })
            .catch((e) => {
              console.log(e);
              setError(e.message);
            });
        })
        .catch((e) => {
          console.log(e);
          setError(e.message);
        });
    } else {
      setCheckedState(false);
      cfs
        .collection("/merchants")
        .doc(`${currentUser.merchantId}`)
        .update({ autoPrint: false })
        .then((d) => console.log("set autoPrint: false"))
        .catch((e) => {
          console.log(e);
          setError(e.message);
        });
    }
  };

  function showConfirm(order: IOrder) {
    confirm({
      title: "คุณแน่ใจหรือไม่ว่าต้องการพิมพ์ ?",
      icon: <ExclamationCircleOutlined />,
      centered: true,

      onOk() {
        console.log("OK");
        closeBill(order);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  function showConfirmPrintAgain(table: string) {
    console.log("table", table);
    confirm({
      title: "คุณแน่ใจหรือไม่ว่าต้องการพิมพ์ใบเสร็จอีกครั้ง ?",
      icon: <ExclamationCircleOutlined />,
      centered: true,

      onOk() {
        console.log("OK : ", table);

        const batch = cfs.batch();
        let order: any = successOrders.find(
          (order) => `${order["table"]}` === table
        );
        console.log("order", order);
        order["docsId"].forEach(
          (id: string, index: number, array: Array<any>) => {
            const ref = cfs
              .collection("orders")
              .doc(`${currentUser.merchantId}`)
              .collection("myOrders")
              .doc(id);
            batch.update(ref, {
              status: "pendingPrint",
            });
            if (array.length - 1 === index) {
              batch
                .commit()
                .then((i) => console.log("print again success"))
                .catch((e) => {
                  console.log(e);
                  setError(e.message);
                });
              console.log("update BIll");
            }
          }
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const updateStatus = (order: IOrder, status: string) => {
    cfs
      .collection("/orders")
      .doc(`${currentUser.merchantId}`)
      .collection("/myOrders")
      .doc(`${order.orderId}`)
      .update({ ...order, status: status })
      .then((d) => {
        console.log("update success");
        setDisablePrintButton(false);
        handleCancellModalSelectEmployee();
      })
      .catch((e) => {
        console.log(e.message);
        setError(e.message);
        setDisablePrintButton(false);
        handleCancellModalSelectEmployee();
      });
  };

  const print = (order: IOrder) => {
    console.log("print");
    setTmpOrder(order);
    handleOpenModalSelectEmployee();
  };

  const handleSelectEmployee = (emp: IEmployee) => {
    setConfirmButton(true);
    setTmpOrder((order) => ({ ...order, employee: emp }));
  };

  const closeBill = async (order: IOrder) => {
    // Get a new write batch
    console.log(order);

    const query = cfs.collection("merchants").doc(`${currentUser.merchantId}`);
    let merchantInfo = await query.get();
    let currentBill = { ...merchantInfo.data() }.currentBill;
    let newBill = currentBill + 1;

    let billId =
      `${moment(new Date()).format("YYYYMMDo")}` +
      `${newBill}`.padStart(4, "0");

    cfs
      .collection("orders")
      .doc(`${currentUser.merchantId}`)
      .collection("myOrders")
      .doc(order.orderId)
      .update({ billId: billId, status: "success" })
      .then((d) => {
        console.log("update BIll");
      })
      .catch((e) => console.log(e));
  };

  const [openModalAddItemToBill, setOpenModalAddItemToBill] = useState<boolean>(
    false
  );

  const [tableSelect, setTableSelect] = useState<string>("");

  const handleOpenModalAddItemToBill = (table: string) => {
    setTableSelect(table);
    setOpenModalAddItemToBill(true);
  };
  const handleCloseModalAddItemToBill = () => {
    setTableSelect("");
    setProductsCart([]);
    setOpenModalAddItemToBill(false);
  };

  const handleCancellModalSelectEmployee = () => {
    setOpenModalSelectEmployee(false);
    setConfirmButton(false);
    setConfirmButtonLoading(false);
    setTmpOrder({
      orderId: "",
      contact: {
        address: "",
        lat: 0,
        lng: 0,
        name: "",
        phone: "",
      },
      status: "",
      createDate: firebase.firestore.Timestamp.now(),
      items: [],
      employee: {
        employeeId: "",
        firstname: "",
        lastname: "",
        description: "",
        phone: "",
        imageUrl: "",
      },
    });
  };
  const handleOpenModalSelectEmployee = () => {
    setOpenModalSelectEmployee(true);
  };

  const onChangeDate = (dates: any, dateStrings: any) => {
    // console.log("From: ", dates[0], ", to: ", dates[1]);
    // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    if (dates && dates[0] && dates[1]) {
      // console.log('dates : ', dates)
      // console.log('dateStrings : ', dateStrings)
      let tmpStartDate = dates[0].startOf("day").toDate();
      let tmpEndDate = dates[1].endOf("day").toDate();
      // console.log('tmpStartDate : ', tmpStartDate)
      // console.log('tmpEndDate : ', tmpEndDate)
      setStartDate(tmpStartDate);
      setEndDate(tmpEndDate);

      // let tmpSaveDate = new Date();
      // localStorage.setItem("startDate", tmpStartDate);
      // localStorage.setItem("endDate", tmpEndDate);
      // localStorage.setItem("saveDate", tmpSaveDate.toString());
    } else {
      setError("วันที่ไม่ถูกต้อง");
    }
  };

  const downloadQR = () => {
    let canvas: HTMLElement | null = document.getElementById("qrprint");

    if (canvas) {
      const definitelyAnElement: HTMLElement = canvas;
      setConfirmButtonLoading(true);
      setConfirmButton(true);
      htmlToImage
        .toJpeg(definitelyAnElement, { quality: 0.95, pixelRatio: 1 })
        .then((dataUrl) => {
          let link = document.createElement("a");
          link.download = `${tmpOrder.employee.firstname}.jpeg`;
          link.href = dataUrl;
          link.click();
          updateStatus(tmpOrder, "pending");
        });
    }
  };

  const handleCancelOrder = (order: IOrder) => {
    console.log("handleCancelOrder : ", order);
    Modal.confirm({
      title: "ยกเลิกรายการ",
      icon: <ExclamationCircleOutlined />,
      content: "คุณต้องการยกเลิกรายการนี้หรือไม่?",
      okText: "ตกลง",
      // okType: "danger",
      cancelText: "ปิด",
      onOk() {
        console.log("OK");
        updateStatus(order, "cancel");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Spin tip="กำลังโหลดข้อมูล.." spinning={loading}>
      <div className="site-card-wrapper">
        <Row gutter={[16, 16]}>
          {width < 992 ? (
            <Col xs={24} style={{ textAlign: "right" }}>
              <ConfigProvider locale={locale}>
                <DatePicker.RangePicker
                  // value={[moment(startDate,'DD-MM-YYYY'), moment(endDate, 'YYYY-MM-DD')]}
                  format="ll"
                  value={[moment(startDate), moment(endDate)]}
                  ranges={{
                    วันนี้: [moment(), moment()],
                    "3 วันที่แล้ว": [moment().subtract(3, "d"), moment()],
                    "5 วันที่แล้ว": [moment().subtract(5, "d"), moment()],
                    อาทิตย์นี้: [
                      moment().startOf("isoWeek"),
                      moment().endOf("isoWeek"),
                    ],
                    เดือนนี้: [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "3 เดือนที่แล้ว": [moment().subtract(3, "M"), moment()],
                    // '6 เดือนที่แล้ว': [moment().subtract(6, 'M'), moment()],
                    ปีที่แล้ว: [moment().subtract(1, "y"), moment()],
                  }}
                  onChange={onChangeDate}
                />
              </ConfigProvider>
            </Col>
          ) : null}
          <Col xs={24} sm={24} md={12} lg={8}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Title level={4} style={{ display: "inline-block" }}>
                ออเดอร์ใหม่
              </Title>
              {/* <div>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={onChange}
                  checked={checkedState}
                />
                {checkedState ? (
                  <Typography
                    style={{
                      display: "inline-block",
                      fontSize: 17,
                      fontWeight: "bold",
                      marginLeft: 7,
                    }}
                  >
                    {checked}
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      display: "inline-block",
                      fontSize: 17,
                      marginLeft: 7,
                      fontWeight: "bold",
                      opacity: 0.2,
                    }}
                  >
                    {checked}
                  </Typography>
                )}
              </div> */}
            </div>

            <hr
              style={{
                color: "#0984e3",
                height: 3,
                backgroundColor: "#0984e3",
                borderWidth: 0,
                borderRadius: 10,
              }}
            ></hr>
            <Card
              bordered={false}
              className="card-shadow"
              style={{ height: "82vh", overflowY: "auto" }}
            >
              {newOrders.length === 0 && (
                <Empty
                  description={<Text type="secondary">ไม่มีรายการ</Text>}
                />
              )}
              {newOrders.map((order: IOrder, indexCard) => {
                return (
                  <div key={`div-${indexCard}`}>
                    <Card
                      key={`Card-${indexCard}`}
                      // title={<Title level={5}>{`งาน`}</Title>}
                      extra={
                        <Title level={5}>{`ลำดับที่ ${indexCard + 1}`}</Title>
                      }
                      bordered={true}
                      style={{
                        width: "100%",
                        boxShadow: "0 3px 20px 0 rgba(0,0,0,.12)",
                        borderRadius: 4,
                        marginBottom: 13,
                      }}
                    >
                      <Descriptions
                        title="ข้อมูลลูกค้า"
                        column={1}
                        layout={"horizontal"}
                        size={"small"}
                      >
                        <Descriptions.Item label="ชื่อ">
                          {`${order["contact"]["name"]}`}
                        </Descriptions.Item>
                        <Descriptions.Item label="ที่อยู่">
                          {`${order["contact"]["address"]}`}
                        </Descriptions.Item>
                        <Descriptions.Item label="ตำแหน่ง">
                          <a
                            type="link"
                            href={`https://www.google.com/maps/search/?api=1&query=${order["contact"]["lat"]},${order["contact"]["lng"]}`}
                            target="_blank"
                          >{`${Number(order["contact"]["lat"]).toFixed(
                            6
                          )},${Number(order["contact"]["lng"]).toFixed(6)}`}</a>
                        </Descriptions.Item>
                        <Descriptions.Item label="โทรศัพท์">
                          {`${order["contact"]["phone"]}`}
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider style={{ width: "100%" }} />
                      <Row>
                        <Col>
                          <Title level={5}>รายการ</Title>{" "}
                        </Col>
                      </Row>

                      {order.items.map((res: IItemCart, index: number) => (
                        <Row
                          justify="space-between"
                          key={`Row-${index}${indexCard}`}
                        >
                          <Col>
                            {" "}
                            <Text
                            // level={5}
                            >{`${res.amount}x ${res.itemName} (${res.price})`}</Text>
                            <p style={{ color: "gray" }}>
                              {res.options.map(
                                (option: IChoiceValue) =>
                                  `${option.name}(${option.price})`
                              )}
                              {res.note !== "" ? `*${res.note}` : ""}
                            </p>
                          </Col>
                          <Col>
                            {" "}
                            <Text
                              type="secondary"
                              // level={5}
                            >{`${numberWithCommas(
                              (res.price + res.totalOption) * res.amount
                            )}฿`}</Text>
                          </Col>
                        </Row>
                      ))}

                      <Divider style={{ margin: 5 }} />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          // marginBottom: 10,
                          fontSize: 16,
                          fontWeight: 900,
                        }}
                      >
                        <Typography style={{ color: "#000" }}>
                          รวมทั้งหมด
                        </Typography>
                        <Typography
                          style={{ color: "#000" }}
                        >{`฿${numberWithCommas(order["total"])}`}</Typography>
                      </div>
                      <br />

                      <div style={{ float: "right" }}>
                        <Button
                          type="ghost"
                          danger
                          style={{ marginRight: 8 }}
                          icon={<CloseOutlined />}
                          onClick={() => handleCancelOrder(order)}
                        >
                          ยกเลิก
                        </Button>
                        {checkedState === true ? null : (
                          <Button
                            disabled={disablePrintButton}
                            type="primary"
                            icon={<PrinterOutlined />}
                            onClick={() => print(order)}
                          >
                            เลือกช่าง
                          </Button>
                        )}
                      </div>
                    </Card>
                  </div>
                );
              })}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Title level={4} style={{ display: "inline-block" }}>
              อยู่ระหว่างดำเนินการ
            </Title>
            <hr
              style={{
                color: "#fbc531",
                height: 3,
                backgroundColor: "#fbc531",
                borderWidth: 0,
                borderRadius: 10,
              }}
            ></hr>
            <Card
              bordered={false}
              className="card-shadow"
              style={{ height: "82vh", overflowY: "auto" }}
            >
              {pendingOrders.length === 0 && (
                <Empty
                  description={<Text type="secondary">ไม่มีรายการ</Text>}
                />
              )}
              {pendingOrders.map((order: IOrder, index) => {
                return (
                  <Spin
                    tip="กำลังพิมพ์.."
                    spinning={order["status"] === "pendingPrint"}
                    key={index}
                  >
                    {" "}
                    <Card
                      key={`Card-${index}`}
                      // title={<Title level={5}>{`งาน`}</Title>}
                      extra={<Title level={5}>{`ลำดับที่ ${index + 1}`}</Title>}
                      bordered={true}
                      style={{
                        width: "100%",
                        boxShadow: "0 3px 20px 0 rgba(0,0,0,.12)",
                        borderRadius: 4,
                        marginBottom: 13,
                      }}
                    >
                      <Descriptions
                        title="ข้อมูลลูกค้า"
                        column={1}
                        layout={"horizontal"}
                        size={"small"}
                      >
                        <Descriptions.Item label="ชื่อ">
                          {`${order["contact"]["name"]}`}
                        </Descriptions.Item>
                        <Descriptions.Item label="ที่อยู่">
                          {`${order["contact"]["address"]}`}
                        </Descriptions.Item>
                        <Descriptions.Item label="ตำแหน่ง">
                          <a
                            type="link"
                            href={`https://www.google.com/maps/search/?api=1&query=${order["contact"]["lat"]},${order["contact"]["lng"]}`}
                            target="_blank"
                          >{`${Number(order["contact"]["lat"]).toFixed(
                            6
                          )},${Number(order["contact"]["lng"]).toFixed(6)}`}</a>
                        </Descriptions.Item>
                        <Descriptions.Item label="โทรศัพท์">
                          {`${order["contact"]["phone"]}`}
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider style={{ width: "100%" }} />
                      <Row>
                        <Col>
                          <Title level={5}>รายการ</Title>{" "}
                        </Col>
                      </Row>

                      {order.items.map((res: IItemCart, index: number) => (
                        <Row
                          justify="space-between"
                          key={`Row-${index}${index}`}
                        >
                          <Col>
                            {" "}
                            <Text
                            // level={5}
                            >{`${res.amount}x ${res.itemName} (${res.price})`}</Text>
                            <p style={{ color: "gray" }}>
                              {res.options.map(
                                (option: IChoiceValue) =>
                                  `${option.name}(${option.price})`
                              )}
                              {res.note !== "" ? `*${res.note}` : ""}
                            </p>
                          </Col>
                          <Col>
                            {" "}
                            <Text
                              type="secondary"
                              // level={5}
                            >{`${numberWithCommas(
                              (res.price + res.totalOption) * res.amount
                            )}฿`}</Text>
                          </Col>
                        </Row>
                      ))}

                      <Divider style={{ margin: 5 }} />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          // marginBottom: 10,
                          fontSize: 16,
                          fontWeight: 900,
                        }}
                      >
                        <Typography style={{ color: "#000" }}>
                          รวมทั้งหมด
                        </Typography>
                        <Typography
                          style={{ color: "#000" }}
                        >{`฿${numberWithCommas(order["total"])}`}</Typography>
                      </div>
                      <br />
                      <div style={{ float: "right" }}>
                        <Button
                          type="ghost"
                          danger
                          style={{ marginRight: 8 }}
                          icon={<CloseOutlined />}
                          onClick={() => handleCancelOrder(order)}
                        >
                          ยกเลิก
                        </Button>
                        <Button
                          type="primary"
                          // icon={<PrinterOutlined />}
                          style={{ float: "right" }}
                          onClick={() => showConfirm(order)}
                        >
                          จบงาน
                        </Button>
                      </div>
                    </Card>
                  </Spin>
                );
              })}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Title level={4} style={{ display: "inline-block" }}>
                {`สำเร็จ (${successOrders.length}) (${numberWithCommas(
                  successOrders
                    .filter((item) => item.status === "success")
                    .reduce((pre, cur) => pre + Number(cur["total"]), 0)
                )} ฿)`}
              </Title>

              {width >= 992 ? (
                <ConfigProvider locale={locale}>
                  <DatePicker.RangePicker
                    // value={[moment(startDate,'DD-MM-YYYY'), moment(endDate, 'YYYY-MM-DD')]}
                    format="ll"
                    value={[moment(startDate), moment(endDate)]}
                    ranges={{
                      วันนี้: [moment(), moment()],
                      "3 วันที่แล้ว": [moment().subtract(3, "d"), moment()],
                      "5 วันที่แล้ว": [moment().subtract(5, "d"), moment()],
                      อาทิตย์นี้: [
                        moment().startOf("isoWeek"),
                        moment().endOf("isoWeek"),
                      ],
                      เดือนนี้: [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "3 เดือนที่แล้ว": [moment().subtract(3, "M"), moment()],
                      // '6 เดือนที่แล้ว': [moment().subtract(6, 'M'), moment()],
                      ปีที่แล้ว: [moment().subtract(1, "y"), moment()],
                    }}
                    onChange={onChangeDate}
                  />
                </ConfigProvider>
              ) : null}
            </div>
            <hr
              style={{
                color: "#4cd137",
                height: 3,
                backgroundColor: "#4cd137",
                borderWidth: 0,
                borderRadius: 10,
              }}
            ></hr>
            <Card
              bordered={false}
              className="card-shadow"
              style={{ height: "82vh", overflowY: "auto" }}
            >
              {successOrders.length === 0 && (
                <Empty
                  description={<Text type="secondary">ไม่มีรายการ</Text>}
                />
              )}
              {successOrders
                .sort(
                  (a: IOrder, b: IOrder) =>
                    b.createDate.toDate().getTime() -
                    a.createDate.toDate().getTime()
                )
                .map((order, index) => (
                  <Card
                    key={`Card-${index}`}
                    title={
                      order.status === "cancel" ? (
                        <Title level={5} type="danger">
                          ถูกยกเลิก
                        </Title>
                      ) : null
                    }
                    extra={<Title level={5}>{`ลำดับที่ ${index + 1}`}</Title>}
                    bordered={true}
                    style={{
                      width: "100%",
                      boxShadow: "0 3px 20px 0 rgba(0,0,0,.12)",
                      borderRadius: 4,
                      marginBottom: 13,
                    }}
                  >
                    <Descriptions
                      title="ข้อมูลลูกค้า"
                      column={1}
                      layout={"horizontal"}
                      size={"small"}
                    >
                      <Descriptions.Item label="ชื่อ">
                        {`${order["contact"]["name"]}`}
                      </Descriptions.Item>
                      <Descriptions.Item label="ที่อยู่">
                        {`${order["contact"]["address"]}`}
                      </Descriptions.Item>
                      <Descriptions.Item label="ตำแหน่ง">
                        <a
                          type="link"
                          href={`https://www.google.com/maps/search/?api=1&query=${order["contact"]["lat"]},${order["contact"]["lng"]}`}
                          target="_blank"
                        >{`${Number(order["contact"]["lat"]).toFixed(
                          6
                        )},${Number(order["contact"]["lng"]).toFixed(6)}`}</a>
                      </Descriptions.Item>
                      <Descriptions.Item label="โทรศัพท์">
                        {`${order["contact"]["phone"]}`}
                      </Descriptions.Item>
                      {order["employee"] ? (
                        <Descriptions.Item label="ชื่อช่าง">
                          {`${order["employee"]["firstname"]} ${order["employee"]["lastname"]}`}
                        </Descriptions.Item>
                      ) : null}
                      {order["employee"] ? (
                        <Descriptions.Item label="เบอร์ช่าง">
                          {`${order["employee"]["phone"]}`}
                        </Descriptions.Item>
                      ) : null}
                    </Descriptions>
                    <Divider style={{ width: "100%" }} />
                    <Row>
                      <Col>
                        <Title level={5}>รายการ</Title>{" "}
                      </Col>
                    </Row>

                    {order.items.map((res: IItemCart, index: number) => (
                      <Row justify="space-between" key={`Row-${index}${index}`}>
                        <Col>
                          {" "}
                          <Text
                          // level={5}
                          >{`${res.amount}x ${res.itemName} (${res.price})`}</Text>
                          <p style={{ color: "gray" }}>
                            {res.options.map(
                              (option: IChoiceValue) =>
                                `${option.name}(${option.price})`
                            )}
                            {res.note !== "" ? `*${res.note}` : ""}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <Text
                            type="secondary"
                            // level={5}
                          >{`${numberWithCommas(
                            (res.price + res.totalOption) * res.amount
                          )}฿`}</Text>
                        </Col>
                      </Row>
                    ))}

                    <Divider style={{ margin: 5 }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // marginBottom: 10,
                        fontSize: 16,
                        fontWeight: 900,
                      }}
                    >
                      <Typography style={{ color: "#000" }}>
                        รวมทั้งหมด
                      </Typography>
                      <Typography
                        style={{ color: "#000" }}
                      >{`฿${numberWithCommas(order["total"])}`}</Typography>
                    </div>
                    <br />
                  </Card>
                ))}
            </Card>
          </Col>
        </Row>
      </div>
      {openModalAddItemToBill ? (
        <ModalAddItem
          choiceGroups={[]}
          products={products}
          productsCart={productsCart}
          customModal={customModal}
          open={openModalAddItemToBill}
          table={tableSelect}
          search={search}
          currentUser={currentUser}
          handleCancel={handleCloseModalAddItemToBill}
          visible={visibleModalOption}
          dataItem={dataItem}
          addItem={addItemCart}
          handleCancelOption={setVisibleModalOption}
          createOrder={createOrder}
        />
      ) : null}
      {openModalSelectEmployee && (
        <ModalSelectEmployee
          confirmButton={confirmButton}
          confirmButtonLoading={confirmButtonLoading}
          downloadQR={downloadQR}
          currentUser={currentUser}
          employees={employees}
          order={tmpOrder}
          open={openModalSelectEmployee}
          handleCancel={handleCancellModalSelectEmployee}
          handleSelectEmployee={handleSelectEmployee}
        />
      )}
    </Spin>
  );
};

export default AdminManagement;

import React, { useState, useEffect } from "react";
import { Row } from "antd";
import ChoiceTable from "./choiceTable";
import ChoiceGroupTable from "./choiceGroupTable";

const ChoiceTab = ({ currentUser }: any) => {
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    if (currentUser.merchantNameTH) {
      document.title = `${currentUser.merchantNameTH} | ${currentUser.merchantNameEN}`;
    }
  }, [currentUser.merchantNameTH]);

  return (
    <div>
      <Row gutter={16}>
        <ChoiceTable
          currentUser={currentUser}
          choices={choices}
          setChoices={setChoices}
        />
        <ChoiceGroupTable currentUser={currentUser} choices={choices} />
      </Row>
    </div>
  );
};

export default ChoiceTab;

import React, { useEffect, useState } from "react";
import { auth, cfs } from "../../../../firebase/firebase";
import {
  Col,
  Row,
  Table,
  Typography,
  Button,
  Switch,
  Modal,
  Popconfirm,
  message,
} from "antd";
import CreateChoiceGroupModal from "./createChoiceGroupModal";
import { IChoiceGroup } from "../../../../models/choiceGroup";
import { IChoice } from "../../../../models/choice";
import firebase from "firebase";
import { IItem } from "../../../../models/Item";

const { Title } = Typography;

const ChoiceGroupTable = ({ currentUser, choices }: any) => {
  const [loadingChoiceGroups, setLoadingChoiceGroups] = useState<boolean>(true);
  const [choiceGroups, setChoiceGroups] = useState<IChoiceGroup[]>([]);
  const [error, setError] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [tmpData, setTmpData] = useState<IChoiceGroup>({
    id: "",
    choiceName: "",
    active: false,
    type: "",
    choices: [],
  });
  const [openDeleteMCG, setopenDeleteMCG] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

  const [
    visibleCreateChoiceGroupModal,
    setVisibleCreateChoiceGroupModal,
  ] = useState<boolean>(false);

  const columnsChoicesGroups = [
    {
      title: "ชื่อกลุ่ม",
      dataIndex: "choiceName",
      key: "choiceName",
      width: 200,
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      key: "type",
      width: 200,
      render: (type: any, record: any) =>
        type === "radio" ? "ตัวเลือกเดียว" : "หลายตัวเลือก",
    },
    {
      title: "เปิดใช้",
      dataIndex: "active",
      key: "active",
      width: 100,
      render: (active: any, record: any) => (
        <Switch
          size="small"
          checked={active}
          onClick={() => handleChoiceGroupsClose(record)}
        />
      ),
    },
    {
      title: "Action",
      key: "itemId",
      width: 100,
      render: (text: string, record: IChoiceGroup) => (
        <a
          onClick={(e) => {
            setopenDeleteMCG(true);
            setTmpData(record);
          }}
        >
          ลบ
        </a>
      ),
    },
  ];

  const handleChoiceGroupsClose = (record: any) => {
    console.log("active : ", record);
    setLoadingChoiceGroups(true);
    cfs
      .collection("merchants")
      .doc(currentUser.merchantId)
      .collection("choiceGroups")
      .doc(`${record.id}`)
      .update({
        active: !record.active,
      })
      .then((d) => {
        setError("");
        setLoadingChoiceGroups(false);
        console.log(`update ${record.id} ${record.itemName}`);
      })
      .catch((e) => {
        console.log(e.message);
        setError(e.message);
        setLoadingChoiceGroups(false);
      });
  };

  useEffect(() => {
    if (!currentUser.merchantId) return;
    setLoadingChoiceGroups(true);
    let unsubscribe = cfs
      .collection("/merchants")
      .doc(`${currentUser.merchantId}`)
      .collection("choiceGroups")
      .onSnapshot({
        next: async (snapshot: any) => {
          setLoadingChoiceGroups(true);
          if (snapshot.size) {
            let tmpChoicesGroups: any = await Promise.all(
              snapshot.docs.map(async (item: any, index: any) => {
                let data = item.data();

                return {
                  ...data,
                  no: index + 1,
                };
              })
            );

            tmpChoicesGroups = await Promise.all(
              tmpChoicesGroups.map(async (i: any) => {
                let tmpChoices = await Promise.all(
                  i.choices.map(async (item: any) => {
                    return (await item.get()).data();
                  })
                );
                tmpChoices = tmpChoices.sort(
                  (a: any, b: any) => a.price - b.price
                );

                return {
                  ...i,
                  choices: tmpChoices,
                };
              })
            );
            setLoadingChoiceGroups(false);

            setChoiceGroups(tmpChoicesGroups);
          } else {
            // it's empty
            setChoiceGroups([]);
            setLoadingChoiceGroups(false);
          }
        },
        error: (error) => {
          console.log(error);
          setError(error.message);
          setLoadingChoiceGroups(false);
        },
      });

    return () => {
      unsubscribe();
    };
  }, [currentUser.merchantId, choices]);

  const expandedRowRender = (columns: any, record: IChoiceGroup) => {
    let data = record.choices.map((item: IChoice, index: number) => ({
      no: index + 1,
      ...item,
      groupId: record.id,
    }));
    return (
      <Table
        columns={columns}
        dataSource={data}
        // showHeader={false}
        rowKey="no"
        pagination={false}
      />
    );
  };

  const handleDeleteCG = (groupChoice: IChoiceGroup) => {
    console.log("handleDeleteCG : ", tmpData);
    setLoadingDelete(true);
    cfs
      .collection("merchants")
      .doc(`${currentUser.merchantId}`)
      .collection("products")
      .where("choices", "array-contains", groupChoice.id)
      .get()
      .then((snapshot) => {
        let batch = firebase.firestore().batch();

        snapshot.docs.forEach((doc) => {
          const docRef = cfs
            .collection("merchants")
            .doc(`${currentUser.merchantId}`)
            .collection("products")
            .doc(doc.id);

          batch.update(docRef, {
            choices: firebase.firestore.FieldValue.arrayRemove(groupChoice.id),
          });
        });
        batch.delete(
          cfs
            .collection("merchants")
            .doc(`${currentUser.merchantId}`)
            .collection("choiceGroups")
            .doc(groupChoice.id)
        );
        batch.commit().then(() => {
          console.log(`Remove group choice from product finish`);
          setLoadingDelete(false);
          setopenDeleteMCG(false);
          setTmpData({
            id: "",
            choiceName: "",
            active: false,
            type: "",
            choices: [],
          });
        });
      })
      .catch((e) => {
        console.log(e);
        setError(e.message);
      });
    // cfs
    //   .collection("merchants")
    //   .doc(`${currentUser.merchantId}`)
    //   .collection("choiceGroups")
    //   .doc(tmpData.id)
    //   .delete()
    //   .then((data) => {
    //     console.log("delete : ", data);
    //     setTmpData({});
    //     setLoadingDelete(false);
    //     setopenDeleteMCG(false);
    //   })
    //   .catch((error) => {
    //     console.log("error : ", error);
    //     setError(error.message);
    //   });
  };

  const handleDeleteChoice = (groupId: string, choiceId: string) => {
    console.log("handleDeleteChoice : ", groupId, choiceId);
    setLoadingChoiceGroups(true);
    cfs
      .collection("merchants")
      .doc(`${currentUser.merchantId}`)
      .collection("choiceGroups")
      .doc(groupId)
      .update({
        choices: firebase.firestore.FieldValue.arrayRemove(
          cfs.doc(`/merchants/${currentUser.merchantId}/choices/${choiceId}`)
        ),
      })

      .then((data) => {
        console.log("delete : ", data);
        setLoadingChoiceGroups(false);
      })
      .catch((error) => {
        setLoadingChoiceGroups(false);
        console.log("error : ", error);
        setError(error.message);
      });
  };
  return (
    <Col xs={24} md={12}>
      <Table
        bordered
        loading={loadingChoiceGroups}
        title={() => (
          <Row justify="space-between">
            <Col>
              <Title level={5}>ตารางรายการกลุ่มตัวเลือกเสริม</Title>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => setVisibleCreateChoiceGroupModal(true)}
              >
                สร้างกลุ่มตัวเลือก
              </Button>
            </Col>
          </Row>
        )}
        className="components-table-demo-nested"
        columns={columnsChoicesGroups}
        rowKey="no"
        expandable={{
          expandedRowRender: (record) => {
            const columnsExpand = [
              {
                title: "ลำดับ",
                dataIndex: "no",
                key: "no",
                align: "center",
                width: 50,
              },
              ,
              {
                title: "ชื่อ",
                dataIndex: "choiceName",
                key: "choiceName",
                width: 200,
              },
              {
                title: "ราคา",
                dataIndex: "price",
                key: "price",
                width: 100,
                align: "center",
              },
              {
                title: "เปิดใช้",
                dataIndex: "active",
                key: "active",
                render: (active: any, record: any) => (
                  <Switch
                    size="small"
                    checked={active}
                    disabled
                    // onClick={() => handleChoiceClose(record)}
                  />
                ),
              },
              {
                title: "Action",
                key: "action",
                width: 100,
                render: (
                  text: any,
                  record: {
                    id: string;
                    choiceName: string;
                    active: boolean;
                    price: number;
                    groupId: string;
                  }
                ) => (
                  <Popconfirm
                    title="คุณต้องการลบรายการนี้ออก?"
                    onConfirm={() =>
                      handleDeleteChoice(record.groupId, record.id)
                    }
                    okText="ยืนยัน"
                    cancelText="ยกเลิก"
                  >
                    <a href="#">ลบออก</a>
                  </Popconfirm>
                ),
              },
            ];

            return expandedRowRender(columnsExpand, record);
          },
        }}
        dataSource={choiceGroups}
        pagination={{ pageSize: pageSize ,pageSizeOptions:["10","20","30"],showSizeChanger:true}}
        scroll={{ y: "100%", x: "max-content" }}
      />

      <Modal
        title="ลบรายการ"
        visible={openDeleteMCG}
        onCancel={(e) => {
          setTmpData({
            id: "",
            choiceName: "",
            active: false,
            type: "",
            choices: [],
          });
          setopenDeleteMCG(false);
        }}
        okText="ลบ"
        cancelText="ยกเลิก"
        footer={[
          <Button
            key="back"
            // type="primary"
            danger
            loading={loadingDelete}
            onClick={() => handleDeleteCG(tmpData)}
          >
            ลบ
          </Button>,
          <Button
            key="submit"
            onClick={() => {
              setTmpData({
                id: "",
                choiceName: "",
                active: false,
                type: "",
                choices: [],
              });
              setopenDeleteMCG(false);
            }}
          >
            ยกเลิก
          </Button>,
        ]}
      >
        <p>
          รหัส: {tmpData.id}
          <br />
          ชื่อ: {tmpData.choiceName}
          <br />
          สถานะ: {tmpData.active ? "เปิดใช้งาน" : "ปิดใช้งาน"}
          <br />
          <br />
          ** ไม่สามารถกู้คืนรายการ
        </p>
      </Modal>

      {visibleCreateChoiceGroupModal && (
        <CreateChoiceGroupModal
          visible={visibleCreateChoiceGroupModal}
          setVisible={setVisibleCreateChoiceGroupModal}
          currentUser={currentUser}
          choices={choices}
        />
      )}
    </Col>
  );
};

export default ChoiceGroupTable;

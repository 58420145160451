import React from "react";
import ReactDOM from "react-dom";
import "./styles/customStyle.css";
import reportWebVitals from "./reportWebVitals";
import Routes from "./routes/routes";
import { ConfigProvider } from "antd";
import th_TH from "antd/es/locale/th_TH";
import {  BrowserRouter as Router, } from "react-router-dom";
ReactDOM.render(
  // <React.StrictMode>
  <ConfigProvider locale={th_TH}>
    <Router>
      <Routes />
    </Router>
  </ConfigProvider>,
  // </React.StrictMode>
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";

export default function Error404() {
  const history = useHistory();

  return (
    <Result
      status="404"
      title="404"
      subTitle="ขออภัยไม่มีหน้าที่คุณเยี่ยมชม"
      extra={
        <Button type="primary" onClick={() => history.goBack()}>
          ย้อนกลับ
        </Button>
      }
    />
  );
}

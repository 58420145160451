import React, { useState, useEffect, useRef } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { Typography, Spin, notification } from "antd";
import { googleMapKey } from "config";

const { Text } = Typography;

const MapWithMarker = withScriptjs(
  withGoogleMap(({ center, setCenter }) => {
    let ref = null;
    const addressRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const distance = (lat1, lon1, lat2, lon2, unit) => {
      if (lat1 === lat2 && lon1 === lon2) {
        return 0;
      } else {
        let radlat1 = (Math.PI * lat1) / 180;
        let radlat2 = (Math.PI * lat2) / 180;
        let theta = lon1 - lon2;
        let radtheta = (Math.PI * theta) / 180;
        let dist =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === "K") {
          dist = dist * 1.609344;
        }
        if (unit === "N") {
          dist = dist * 0.8684;
        }
        return dist;
      }
    };

    const handlePantoCurrentLocation = () => {
      // let { map, maps } = this.state
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // console.log('position : ', position)
            let tmpCenter = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            let tmpDistance = distance(
              tmpCenter.lat,
              tmpCenter.lng,
              center["lat"],
              center["lng"],
              "M"
            );
            console.log("tmpDistance", tmpDistance);
            if (tmpDistance <= 50) {
              setCenter(tmpCenter);
            }
            // setCenter(tmpCenter)
            setLoading(false);
          },
          () => {
            console.log("get currented");
            if (addressRef && addressRef.current) {
              addressRef.current = null;
            } else {
              setError(
                "ไม่สามารถเข้าถึงตำแหน่งปัจจุบัน โปรดอนุญาติให้สามารถเข้าถึงตำแหน่งปัจจุบัน"
              );
            }
            setLoading(false);
          }
        );
      } else {
        // Browser doesn't support Geolocation
        console.log(`Browser doesn't support Geolocation`);
        setLoading(false);
        setError("บราวเซอร์ของคุณไม่รองรับ");
      }
    };

    useEffect(() => {
      console.log("error :>> ", error);
      if (error) {
        notification.error({
          message: "เกิดข้อผิดพลาด",
          description: error,
          placement: "bottomRight",
          onClick: () => {
            setError("");
          },
          onClose: () => {
            setError("");
          },
        });
      }
    }, [error]);

    useEffect(() => {
      handlePantoCurrentLocation();
    }, []);

    return (
      <Spin spinning={loading}>
        <GoogleMap
          zoom={14}
          center={center}
          onCenterChanged={() => {
            let lat = ref.getCenter().lat();
            let lng = ref.getCenter().lng();
            setCenter({ lat, lng });
          }}
          ref={(mapRef) => (ref = mapRef)}
          options={{
            streetViewControl: false,
            scaleControl: true,
            fullscreenControl: false,
            gestureHandling: "auto",
            minZoom: 8,
            maxZoom: 18,
            mapTypeControl: true,
            zoomControl: true,
            clickableIcons: false,
            // scrollwheel: true
          }}
          centerAroundCurrentLocation
        >
          <Marker position={center} />
        </GoogleMap>
      </Spin>
    );
  })
);

const GoogleMapCustom = (props) => {
  return (
    <MapWithMarker
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapKey}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `inherit` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      {...props}
    />
  );
};

export default GoogleMapCustom;

import React, { useState, useEffect, useRef } from "react";
import {
  Badge,
  Avatar,
  Input,
  Typography,
  Layout,
  Select,
  Spin,
  Carousel,
} from "antd";
import { SearchOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { cfs } from "../../firebase/firebase";
import { IItem } from "../../models/Item";
import { IItemCart } from "../../models/ItemCart";
// import { ModalOptions } from "./components/index";
import { ModalOptions, OrdersSelect } from "../../components";
import { useCarts, groupBy } from "../../utility";
import { Link } from "react-router-dom";
import { IChoiceGroup } from "../../models/choiceGroup";
import "./customerOrder.css";
import { ICarousel } from "models/carousel";
import ReactGA from "react-ga";

const { Header, Content } = Layout;

const { Title } = Typography;

interface ParamTypes {
  idMerchant: string;
  tableNumber: string;
}

const CustomerOrder = () => {
  const history = useHistory();
  let { idMerchant, tableNumber } = useParams<ParamTypes>();
  const Ref = useRef<Array<any>>([]);
  const RefModal = useRef<any>(null);
  const [carts, setCarts] = useCarts<Array<IItemCart>>();
  const [products, setProducts] = useState<object>({});
  const [currentUser, setCurrentUser] = useState<any>({});
  const [tableNo, setTableNo] = useState(-1);
  const [selectCategory, setSelectCategory] = useState(-1);
  const [count, setCount] = useState(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [opacity, setOpacity] = useState<number>(0);
  const [viewCart, setViewCart] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [choiceGroups, setChoiceGroups] = useState<IChoiceGroup[]>([]);
  const [carousels, setCarousels] = useState<ICarousel[]>([]);

  const [dataItem, setDataItem] = useState<IItem>({
    productId: "",
    itemName: "",
    price: 0,
    category: "",
    imageUrl: "",
    options: [],
    choices: [],
  });
  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  const scrollToRefObject = (ref: any, val: number) => {
    // console.log('ref :>> ', ref);
    if (ref && ref.offsetTop) {
      if (val == 0) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, ref.offsetTop);
      }
    } else if (val == 0) {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    window.onscroll = () => {
      if (!viewCart) handleScroll();
    };
  }, [products]);

  useEffect(() => {
    if (idMerchant) {
      setLoading(true);
      cfs
        .collection("merchants")
        .doc(idMerchant)
        .get()
        .then((doc: any) => {
          let tmpCurrentUser: any = doc.data();

          document.title = `${tmpCurrentUser.merchantNameTH} | ${tmpCurrentUser.merchantNameEN}`;
          setCurrentUser(tmpCurrentUser);
          setTableNo(Number(tableNumber));
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    }
  }, [idMerchant]);

  useEffect(() => {
    let unsubscribe: any;
    if (idMerchant) {
      setLoading(true);
      unsubscribe = cfs
        .collection("merchants")
        .doc(`${idMerchant}`)
        .collection("products")
        .onSnapshot({
          next: async (snapshot) => {
            if (snapshot.size) {
              let tmpData: any = await Promise.all(
                snapshot.docs.map((i) => i.data())
              );
              tmpData = tmpData.filter(
                (item: any) => item.active === true || item.active === undefined
              );
              let tmpIsSuggest: any = tmpData.filter(
                (item: any) => item.isSuggest === true
              );
              let tmpProducts: any = {};
              if (tmpIsSuggest.length > 0) {
                tmpProducts = { เมนูแนะนำ: [...tmpIsSuggest], ...tmpProducts };
              }

              tmpProducts = { ...tmpProducts, ...groupBy(tmpData, "category") };
              // console.log("tmpProducts :>> ", tmpProducts);
              setProducts(tmpProducts);
              setSelectCategory(0);
              setError("");
              setLoading(false);
            } else {
              setProducts([]);
              setError("");
              setLoading(false);
            }
          },
          error: (error) => {
            console.log(error);
            setError(error.message);
            setLoading(false);
          },
        });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [idMerchant]);

  useEffect(() => {
    let unsubscribe: any;
    if (idMerchant) {
      setLoading(true);
      unsubscribe = cfs
        .collection("merchants")
        .doc(`${idMerchant}`)
        .collection("choiceGroups")
        .onSnapshot({
          next: async (snapshot) => {
            if (snapshot.size) {
              let pros: IChoiceGroup[] = await Promise.all(
                snapshot.docs.map(async (i: any) => {
                  const data = i.data();
                  const choices = await Promise.all(
                    data.choices.map(async (choice: any) =>
                      (await choice.get()).data()
                    )
                  );
                  return { ...data, choices: choices };
                })
              );
              // console.log(pros);
              setChoiceGroups(pros);
              setError("");
              setLoading(false);
            } else {
              setChoiceGroups([]);
              setError("");
              setLoading(false);
            }
          },
          error: (error) => {
            console.log(error);
            setError(error.message);
            setLoading(false);
          },
        });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [idMerchant]);

  useEffect(() => {
    let unsubscribe: any;
    if (currentUser.merchantId) {
      unsubscribe = cfs
        .collection("/merchants")
        .doc(`${currentUser.merchantId}`)
        .collection("carousels")
        .onSnapshot({
          next: async (snapshot) => {
            if (snapshot.size) {
              let tmpcarousels: any = await Promise.all(
                snapshot.docs.map(async (item, index) => {
                  return { ...item.data(), id: item.id };
                })
              );

              setCarousels(
                tmpcarousels.sort((a: ICarousel, b: ICarousel) => a.no - b.no)
              );
              setLoading(false);
            } else {
              // it's empty
              setCarousels([]);
              setLoading(false);
            }
          },
          error: (error) => {
            console.log(error);
            setError(error.message);
            setLoading(false);
          },
        });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser.merchantId]);

  const handleSelected = (val: number) => {
    // console.log("val : ", val);
    setSelectCategory(val);
    scrollToRefObject(Ref.current[val], val);
  };

  const map = (
    value: number,
    in_min: number,
    in_max: number,
    out_min: number,
    out_max: number
  ) => ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;

  const handleScroll = () => {
    // console.log("object :>> ", selectCategory);
    let scrollTop: any = document.documentElement.scrollTop;

    setOpacity(map(scrollTop, 0, 140, 0, 1));
    Ref.current.map((target: any, index: number) => {
      if (target && target.offsetTop != null) {
        let offsetTop = target.offsetTop - 100;
        let offsetHeight = target.offsetHeight;

        if (scrollTop > offsetTop && scrollTop < offsetTop + offsetHeight) {
          setSelectCategory(index);
        }
        //  else {
        //   setSelectCategory(0);
        // }
      }
    });
  };

  const customModal = (data: IItem) => {
    ReactGA.event({
      category: "Customer Order",
      action: "Open Modal",
      label: `select ${data.itemName}`,
    });
    console.log("event");
    setCount((count: number) => count + 1);
    setVisible(true);
    setDataItem(data);
  };

  const addItem = (data: IItemCart) => {
    ReactGA.event({
      category: "Customer Order",
      action: "Add product to cart",
      label: `product ${data.itemName}`,
    });
    setCarts((carts: Array<IItemCart>) => [...carts, data]);
    setVisible(false);
  };

  return (
    <Layout>
      <Badge count={carts.length} className="fix-widget-badge"></Badge>

      <Link to={`/${idMerchant}/cart`}>
        <Avatar
          className="fix-widget"
          size={50}
          icon={<ShoppingCartOutlined />}
        ></Avatar>
      </Link>

      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          // background: "tran",
          backgroundColor: `rgba(255,255,255,${opacity})`,
          boxShadow: "0px 0px 8px 1px rgba(41,41,41,.25)",
          height: "60px",
          padding: "0px",
          lineHeight: "unset",
        }}
      >
        {/* <div style={{ textAlign: "center", marginTop: "12px" }}>
          <Title level={4} style={{ margin: "auto" ,width: "100%"}}>
            {currentUser.merchantNameTH}
          </Title>
        </div> */}
        <div
          style={{
            padding: "15px 15px 15px 15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Input
            className="customInput"
            placeholder={currentUser.merchantNameTH}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
            style={{
              width: "65%",
              // borderRadius: "20px",
              backgroundColor: "#f5f5f5",
              border: "unset",
            }}
          />
          <Select
            className="customSelect"
            style={{ width: "35%", marginLeft: "6px" }}
            // id="customSelect"
            // defaultValue={selectCategory}
            value={selectCategory}
            onChange={(e) => handleSelected(e)}
          >
            {Object.keys(products).length > 0 ? (
              Object.keys(products).map((category, index) => (
                <Select.Option key={`select-${index}`} value={index}>
                  {category}
                </Select.Option>
              ))
            ) : (
              <Select.Option key={`select--1`} value={-1}>
                ไม่ทราบ
              </Select.Option>
            )}
          </Select>
        </div>
      </Header>
      <Carousel autoplay>
        {carousels.map((slice: ICarousel, index: number) => (
          <div key={`Carousel-${index}`}>
            <div
              style={{
                // minHeight: "35vh",
                minHeight: "36vh",
                position: "relative",
                background: slice.background,
              }}
            >
              {" "}
              <img
                src={slice.imageUrl}
                style={{
                  // maxHeight: "26vh",
                  maxHeight: "28vh",
                  maxWidth: "100%",
                  margin: "auto",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              />
            </div>
          </div>
        ))}
      </Carousel>

      <Content style={{ background: "#fff" }}>
        <div
          style={{
            // padding: "85px 15px 0px 15px",
            // padding: "65px 15px 0px 15px",
            padding: "0px 15px 0px 15px",
            overflowY: "auto",
            minHeight: "100vh",
          }}
        >
          <Spin spinning={loading}>
            <OrdersSelect
              products={products}
              search={search}
              Ref={Ref}
              customModal={customModal}
            />
          </Spin>
        </div>
        {/* <button onClick={e => createProduct()}>เพิ่ม</button> */}
      </Content>
      {visible ? (
        <ModalOptions
          dataItem={dataItem}
          choiceGroups={choiceGroups}
          visible={visible}
          handleCancel={(value, data) => {
            setVisible(value);
            ReactGA.event({
              category: "Customer Order",
              action: "Cancel Modal",
              label: `Cancel select ${data.itemName}`,
            });
          }}
          addItem={addItem}
        />
      ) : null}
    </Layout>
  );
};

export default CustomerOrder;

import React, { useEffect, useState, useRef } from "react";
import { cfs } from "../../../../firebase/firebase";
import {
  Col,
  Row,
  Table,
  Typography,
  Button,
  Switch,
  Modal,
  notification,
  Input,
  Space,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddChoiceModal from "./addChoiceModal";
import { CSVLink } from "react-csv";
import { sortFunction } from "../../../../utility/index";
import Highlighter from "react-highlight-words";

const { Title } = Typography;

const headers = [
  { label: "ลำดับ", key: "no" },
  { label: "ชื่อ", key: "choiceName" },
  { label: "ราคา", key: "price" },
  { label: "เปิดขาย", key: "active" },
];

const ChoiceGroupTable = ({ currentUser, choices, setChoices }: any) => {
  const [visibleAddChoiceModal, setVisibleAddChoiceModal] = useState<boolean>(
    false
  );
  const [loadingChoice, setLoadingChoice] = useState<boolean>(true);
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<any>(null);
  const [error, setError] = useState<string>("");
  const [searchedColumn, setSearchedColumn] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`ค้นหา`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(
              e.target.value ? [e.target.value.toLowerCase()] : []
            )
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            ค้นหา
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            ล้าง
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase().trim())
        : "",
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => {
          searchInput.current.focus();
        }, 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: any = [
    {
      title: "ลำดับ",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 50,
      sorter: (a: any, b: any) => sortFunction(a, b, "no"),
    },
    ,
    {
      title: "ชื่อ",
      dataIndex: "choiceName",
      key: "choiceName",
      width: 200,
      ...getColumnSearchProps("choiceName"),
      sorter: (a: any, b: any) => sortFunction(a, b, "choiceName"),
    },
    {
      title: "ราคา",
      dataIndex: "price",
      key: "price",
      width: 100,
      align: "center",
      onFilter: (value: any, record: any) => record.price.indexOf(value) === 0,
      sorter: (a: any, b: any) => sortFunction(a, b, "price"),
    },

    {
      title: "เปิดขาย",
      align: "center",
      key: "active",
      dataIndex: "active",
      width: 150,
      filters: [
        {
          text: "เปิดขาย",
          value: true,
        },
        {
          text: "ปิดขาย",
          value: false,
        },
      ],
      onFilter: (value: any, record: any) => {
        return record.active === value;
      },
      render: (active: any, record: any) => (
        <Switch
          size="small"
          checked={active}
          onClick={() => handleChoiceClose(record)}
        />
      ),
      sorter: (a: any, b: any) => sortFunction(a, b, "active"),
    },
  ];

  const handleChoiceClose = (record: any) => {
    console.log("active : ", record);
    setLoadingChoice(true);
    cfs
      .collection("merchants")
      .doc(`${currentUser.merchantId}`)
      .collection("choices")
      .doc(`${record.id}`)
      .update({
        active: !record.active,
      })
      .then((d) => {
        setError("");
        setLoadingChoice(false);
        console.log(`update ${record.id} ${record.choiceName}`);
      })
      .catch((e) => {
        console.log(e.message);
        setError(e.message);
        setLoadingChoice(false);
      });
  };

  const onChange = (
    pagination: any,
    filters: any,
    sorter: any,
    action: any
  ) => {
    // console.log("params", pagination, filters, sorter, action);
    setPageSize(pagination.pageSize);
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0] ? selectedKeys[0].trim() : "");
    setSearchedColumn(dataIndex ? dataIndex.trim() : "");
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    if (error) {
      notification.error({
        message: "เกิดข้อผิดพลาด",
        description: error,
        placement: "bottomRight",
        onClick: () => {
          setError("");
        },
      });
    }
  }, [error]);

  useEffect(() => {
    if (!currentUser.merchantId) return;
    setLoadingChoice(true);
    let unsubscribe = cfs
      .collection("/merchants")
      .doc(`${currentUser.merchantId}`)
      .collection("choices")
      .onSnapshot({
        next: async (snapshot) => {
          if (snapshot.size) {
            let tmpChoices = await Promise.all(
              snapshot.docs.map(async (item, index) => await item.data())
            );
            tmpChoices = tmpChoices.sort((a, b) => a.price - b.price);
            tmpChoices = tmpChoices.map((item, index) => ({
              ...item,
              no: index + 1,
            }));
            setChoices(tmpChoices);
            setLoadingChoice(false);
          } else {
            // it's empty
            setLoadingChoice(false);
            setChoices([]);
          }
        },
        error: (error) => {
          console.log(error);
          setError(error.message);
          setLoadingChoice(false);
        },
      });

    return () => {
      unsubscribe();
    };
  }, [currentUser.merchantId]);

  return (
    <Col xs={24} md={12}>
      <Table
        bordered
        loading={loadingChoice}
        title={() => (
          <Row
            justify="space-between"
            //  justify="end"
          >
            <Col>
              <Title level={5}>ตารางรายการตัวเลือกเสริม</Title>
            </Col>
            <Col>
              <CSVLink
                data={choices}
                headers={headers}
                filename={"ตารางรายการตัวเลือกเสริม.csv"}
                className="ant-btn ant-btn-link"
                target="_blank"
              >
                บันทึกเป็น CSV
              </CSVLink>
              <Button
                // disabled
                type="primary"
                onClick={() => setVisibleAddChoiceModal(true)}
              >
                เพิ่มตัวเลือก
              </Button>
            </Col>
          </Row>
        )}
        onChange={onChange}
        columns={columns}
        dataSource={choices}
        rowKey="no"
        pagination={{ pageSize: pageSize ,pageSizeOptions:["10","20","30"],showSizeChanger:true}}
        scroll={{ y: "100%", x: "max-content" }}
      />
      {visibleAddChoiceModal && (
        <AddChoiceModal
          visible={visibleAddChoiceModal}
          setVisible={setVisibleAddChoiceModal}
          currentUser={currentUser}
        />
      )}
    </Col>
  );
};

export default ChoiceGroupTable;

import React from "react";
import {
  Typography,
  Row,
  Col,
  Card,
  Divider,
  Switch,
  Button,
  Modal,
  Spin,
  DatePicker,
  Space,
} from "antd";
import { IItemCart } from "../../models/ItemCart";
import { DeleteOutlined, LeftOutlined } from "@ant-design/icons";
import { IChoiceValue } from "../../models/choiceValue";

const { Title } = Typography;

interface ParamTypesRenderItems {
  order: IItemCart[];
  removeItem: (item: IItemCart) => void;
  remove: boolean;
}
const RenderItems = ({ order, removeItem, remove }: ParamTypesRenderItems) => {
  // console.log("order", order);
  return (
    <div>
      {order.map((res: IItemCart, index: number) => (
        <div key={index}>
          <Row justify="space-between" key={index}>
            <Col>
              {" "}
              <Title
                style={{ lineHeight: "32px", margin: "0px" }}
                level={5}
              >{`${res.amount}x ${res.itemName} (${res.price})`}</Title>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Title
                    style={{ lineHeight: "32px", margin: "0px" }}
                    type="secondary"
                    level={5}
                  >{`${(res.price + res.totalOption) * res.amount}฿`}</Title>
                </Col>
                <Col>
                  {remove && (
                    <Button
                      // type="dashed"
                      id={`delete-${new Date().getTime()}`}
                      onClick={() => removeItem(res)}
                      type="link"
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <p style={{ color: "gray" }}>
            {res.options &&
              res.options.map(
                (option: IChoiceValue) => `${option.name}(${option.price}) `
              )}
            {res.note !== "" ? `*${res.note}` : ""}
          </p>
        </div>
      ))}
    </div>
  );
};

export default RenderItems;

import React, { useState, useRef } from "react";
import {
  Layout,
  Menu,
  Dropdown,
  Typography,
  Modal,
  Row,
  Col,
  Select,
  Card,
  Checkbox,
  Button,
} from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { IMerchant } from "../../../../models/merchant";
import { IItem } from "../../../../models/Item";
import { IItemCart, ItemCart } from "../../../../models/ItemCart";
import { OrdersSelect, ModalOptions } from "../../../../components";
import { ChoiceGroup, IChoiceGroup } from "../../../../models/choiceGroup";
import { IChoiceValue } from "../../../../models/choiceValue";
const { Option } = Select;
const { Header } = Layout;
const { Title } = Typography;

type ModalAddItemProps = {
  open: boolean;
  table: string;
  search: string;
  currentUser: any;
  products: object;
  productsCart: Array<IItemCart>;
  choiceGroups: IChoiceGroup[];
  customModal: (item: IItem) => void;
  handleCancel: () => void;
  visible: boolean;
  dataItem: IItem;
  addItem: (data: IItemCart) => void;
  createOrder: (table: string) => void;
  handleCancelOption: React.Dispatch<React.SetStateAction<boolean>>;
};
const ModalAddItem = ({
  open,
  currentUser,
  products,
  table,
  search,
  productsCart,
  choiceGroups,
  customModal,
  handleCancel,
  visible,
  dataItem,
  addItem,
  handleCancelOption,
  createOrder,
}: ModalAddItemProps) => {
  const [tableSelect, setTableSelect] = useState(table);
  const Ref = useRef<Array<any>>([]);
  function handleChange(value: string) {
    console.log(`โต๊ะที่  ${value}`);
    setTableSelect(value);
  }

  return (
    <Modal
      title="เปิดบิล"
      centered
      visible={open}
      onCancel={() => handleCancel()}
      width={800}
      footer={[
        <Button htmlType="submit" key="cancel" onClick={() => handleCancel()}>
          ยกเลิก
        </Button>,
        <Button
          htmlType="submit"
          type="primary"
          key="submit"
          disabled={productsCart.length === 0}
          onClick={() => createOrder(tableSelect)}
        >
          ตกลง
        </Button>,
      ]}
    >
      <Card bordered={false}>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={24}>
                {" "}
                <Select
                  defaultValue={`${table}`}
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  size={"large"}
                >
                  {Array.from(Array(currentUser.tables).keys()).map((value) => (
                    <Option key={value + 1} value={`${value + 1}`}>{`โต๊ะที่ ${
                      value + 1
                    }`}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div
                  style={{
                    padding: "10px 0px 0px 0px",
                    overflowY: "auto",
                    height: "50vh",
                  }}
                >
                  <OrdersSelect
                    products={products}
                    search={search}
                    Ref={Ref}
                    customModal={customModal}
                  />
                </div>
              </Col>{" "}
            </Row>
          </Col>
          <Col span={12}>
            <div
              style={{
                padding: "10px 10px 10px 10px",
                overflowY: "auto",
                height: "50vh",
              }}
            >
              {productsCart.map((res: IItemCart, index: number) => (
                <Row justify="space-between" key={`itemsRow-${index}`}>
                  <Col>
                    {" "}
                    <Title
                      level={5}
                    >{`${res.amount}x ${res.itemName} (${res.price})`}</Title>
                    <p style={{ color: "gray" }}>
                      {res.options.map(
                        (option: IChoiceValue) =>
                          `${option.name}(${option.price})`
                      )}
                    </p>
                  </Col>
                  <Col>
                    {" "}
                    <Title type="secondary" level={5}>{`${
                      (res.price + res.totalOption) * res.amount
                    }฿`}</Title>
                  </Col>
                </Row>
              ))}
            </div>
          </Col>
        </Row>
      </Card>
      {visible && (
        <ModalOptions
          visible={visible}
          choiceGroups={choiceGroups}
          dataItem={dataItem}
          addItem={addItem}
          handleCancel={handleCancelOption}
        />
      )}
    </Modal>
  );
};

export default ModalAddItem;

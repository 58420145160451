import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  notification,
  Spin,
} from "antd";
import "./login.css";
import { auth } from "../../firebase/firebase";

const Login = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const onFinish = (values: any) => {
    console.log("onFinish:", values);
    let { email, password } = values;
    setLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        console.log("success");
        // setLoading(false)
      })
      .catch((e) => {
        console.log("e", e.message);
        setLoading(false);
        setError(e.message);
      });
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({
        message: "เกิดข้อผิดพลาด",
        description: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
        placement: "bottomRight",
        onClick: () => {
          setError("");
        },
        onClose: () => {
          setError("");
        },
      });
    }
  }, [error]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("onFinishFailed:", errorInfo);
  };

  return (
    <Row justify="center" align="middle" className="site-layout-content">
      <Col>
        <Spin tip="กำลังโหลดข้อมูล.." spinning={loading}>
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="ชื่อผู้ใช้"
              name="email"
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกชื่อผู้ใเช้!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="รหัสผ่าน"
              name="password"
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกรหัสผ่าน!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout} name="remember" valuePropName="checked">
              <Checkbox>จำฉันไว้</Checkbox>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                เข้าสู่ระบบ
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Col>
    </Row>
  );
};

export default Login;

import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Button,
  Input,
  Typography,
  Checkbox,
  Radio,
  Row,
  Col,
} from "antd";
import { IItem } from "../../models/Item";
import { IOption } from "../../models/Option";
import { IItemCart } from "../../models/ItemCart";
import { IChoiceGroup } from "../../models/choiceGroup";
import { IChoice } from "../../models/choice";
import { IChoiceValue } from "../../models/choiceValue";
import Login from "../../views/login";
import { logDOM } from "@testing-library/react";
import { isJson } from "../../utility/index";

const { Text } = Typography;
const { TextArea } = Input;

type ModalOptionsProps = {
  visible: boolean;
  dataItem: IItem;
  choiceGroups: IChoiceGroup[];
  addItem: (data: IItemCart) => void;
  // handleCancel: React.Dispatch<React.SetStateAction<boolean>>;
  handleCancel: (value: boolean, data: IItem) => void;
};

const ModalOptions = ({
  visible,
  dataItem,
  choiceGroups,
  addItem,
  handleCancel,
}: ModalOptionsProps) => {
  const [form] = Form.useForm();
  const [amount, setAmount] = useState(1);
  const [radioValue, setRadioValue] = useState({});
  const [checkboxValue, setCheckboxValue] = useState([]);
  const [myChoiceGroup, setMyChoiceGroup] = useState<IChoiceGroup[]>([]);

  const onChangeRadio = (e: any, key: string) => {
    console.log("radio checked", e.target.value);
    let newObject: any = { ...radioValue };
    newObject[key] = JSON.parse(e.target.value);
    setRadioValue(newObject);
    console.log("newObject1 : ", newObject);
  };

  const onChangeCheckbox = (e: any) => {
    console.log("checkbox checked", e);
    // let newObject: any = [...checkboxValue, e.target.value];
    setCheckboxValue(e);
    // console.log("newObject2 : ", newObject);
  };

  const setDefualtValue = (data: IItem) => {
    const object =
      data.options != undefined
        ? data.options.reduce(
            (previousValue: object, currentValue: IOption) => {
              if (currentValue.type !== "checkbox") {
                return {
                  ...previousValue,
                  [currentValue.key]: currentValue.data[0],
                  ...radioValue,
                };
              } else {
                return {
                  ...previousValue,
                };
              }
            },
            {}
          )
        : {};
    console.log(object);
    return object;
  };

  const onFinish = (values: object) => {
    Object.keys(values).forEach(
      (key) => values[key] === undefined && delete values[key]
    );

    let options = Object.keys(values).reduce(
      (previousValue: any, key: string) => {
        console.log("key :>> ", key);
        console.log("values[key] :>> ", values[key]);
        if (Array.isArray(values[key])) {
          let tmp = values[key].map((item: any) => JSON.parse(item));
          return [...previousValue, ...tmp];
          // return [...previousValue, ...values[key]];
        } else if (
          (typeof values[key] === "string" || values[key] instanceof String) &&
          isJson(values[key])
        ) {
          return [...previousValue, JSON.parse(values[key])];
        }
        // else if (JSON.parse(values[key]) instanceof Object) {
        //   return [...previousValue, JSON.parse(values[key])];
        // }
        else {
          return previousValue;
        }
      },
      []
    );

    let item = {
      id: `${new Date().getTime()}`,
      itemName: dataItem.itemName,
      category: dataItem.category,
      price: dataItem.price,
      amount: values["amount"],
      note: values["note"] ? values["note"] : "",
      options: options,
      totalOption: options.reduce(
        (pre: number, crr: IChoiceValue) => pre + crr.price,
        0
      ),
    };

    addItem(item);
    form.resetFields();
  };

  const validateForm = () => {
    console.log("validateForm");
    form.submit();
  };

  const newRenderCheck = (
    title: string,
    choice: IChoice[],
    mIndex: number,
    key: string
  ) => (
    <div key={key}>
      <Text strong>{title}</Text>
      <Form.Item key={key} id={key} name={title} style={{ marginTop: "10px" }}>
        <Checkbox.Group
          value={checkboxValue}
          onChange={(e) => onChangeCheckbox(e)}
        >
          <Row>
            {choice
              .sort((a, b) => a.price - b.price)
              .map((item: IChoice, index: number) => (
                <Col span={24} key={`Checkbox-${index}-${mIndex}`}>
                  <Checkbox
                    value={JSON.stringify({
                      name: item.choiceName,
                      price: item.price,
                      type: "checkbox",
                      key: title,
                    })}
                    disabled={!item.active}
                  >
                    {item.choiceName}({item.price})
                  </Checkbox>
                </Col>
              ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
    </div>
  );

  const newRenderRadio = (
    title: string,
    choice: IChoice[],
    mIndex: number,
    key: string
  ) => {
    return (
      <div key={key}>
        <Text strong>{title}</Text>
        <Form.Item
          key={key}
          id={key}
          name={title}
          rules={[{ required: true }]}
          style={{ marginTop: "10px" }}
        >
          <Radio.Group
            value={radioValue[title]}
            onChange={(e) => onChangeRadio(e, title)}
          >
            <Row>
              {choice
                .sort((a, b) => a.price - b.price)
                .map((item: IChoice, index: number) => (
                  <Col span={24} key={`Radio-${index}-${mIndex}`}>
                    <Radio
                      value={JSON.stringify({
                        name: item.choiceName,
                        price: item.price,
                        type: "radio",
                        key: title,
                      })}
                      disabled={!item.active}
                    >
                      {item.choiceName}({item.price})
                    </Radio>
                  </Col>
                ))}
            </Row>
          </Radio.Group>
        </Form.Item>
      </div>
    );
  };

  const renderAmount = (amount: number) => {
    return (
      <div>
        <Row>
          <Col>
            <Text strong>จำนวน</Text>
          </Col>
        </Row>
        <Row justify="center">
          <Col style={{ textAlign: "center" }}>
            {" "}
            <Button
              size="large"
              onClick={() => {
                form.setFieldsValue({
                  amount: amount > 1 ? amount - 1 : amount,
                });
                setAmount((a) => (a > 1 ? a - 1 : a));
              }}
            >
              -
            </Button>
          </Col>
          <Col span={5}>
            <Form.Item key="amount" id="amount" name="amount">
              <Input
                style={{ textAlign: "center" }}
                size="large"
                type="number"
                min={1}
                disabled
                onChange={(e) => setAmount(Number(e.target.value))}
                value={amount}
              />
            </Form.Item>
          </Col>
          <Col style={{ textAlign: "center" }}>
            {" "}
            <Button
              size="large"
              onClick={() => {
                form.setFieldsValue({ amount: amount + 1 });
                setAmount((a) => a + 1);
              }}
            >
              +
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  const renderNote = () => (
    <div>
      <div>
        <Text strong>คำแนะนำพิเศษ</Text>
        <Text disabled> ไม่จำเป็นต้องระบุ</Text>
      </div>
      <Form.Item key="note" id="note" name="note" style={{ marginTop: "10px" }}>
        {/* <TextArea placeholder="เช่น ไม่ใส่ถั่วงอก" /> */}
        <TextArea />
      </Form.Item>
    </div>
  );

  const validateMessages = {
    required: "ต้องระบุ!",
  };

  useEffect(() => {
    let itemChoice = dataItem.choices;

    let result: any = itemChoice.map((item: string) =>
      choiceGroups.find((choice: IChoiceGroup) => item === choice.id)
    );

    setMyChoiceGroup(result);
  }, []);

  return (
    <Modal
      title="ปรับแต่ง"
      visible={visible}
      onCancel={() => handleCancel(false, dataItem)}
      style={{ top: 10 }}
      footer={[
        <Button key="back" onClick={() => handleCancel(false, dataItem)}>
          ปิด
        </Button>,
        <Button
          htmlType="submit"
          key="submit"
          type="primary"
          onClick={validateForm}
        >
          ตกลง
        </Button>,
      ]}
    >
      <div style={{ overflow: "auto", maxHeight: "65vh" }}>
        <Form
          key={`${Date.now()}`}
          form={form}
          name="validate_other"
          onFinish={onFinish}
          initialValues={{ amount: 1 }}
          validateMessages={validateMessages}
          scrollToFirstError={true}
        >
          {myChoiceGroup &&
            myChoiceGroup.map((value: IChoiceGroup, index: number) => {
              if (value.type === "radio" && value.active) {
                return newRenderRadio(
                  value.choiceName,
                  value.choices,
                  index,
                  `renderRadio-${index}`
                );
              } else if (value.type === "checkbox" && value.active) {
                return newRenderCheck(
                  value.choiceName,
                  value.choices,
                  index,
                  `renderCheck-${index}`
                );
              }
            })}

          {renderNote()}
          {renderAmount(amount)}
        </Form>
        {/* <Button onClick={() => setAmount((amount) => amount + 1)}>ccc</Button> */}
      </div>
    </Modal>
  );
};

export default ModalOptions;

import React, { useState, useEffect } from "react";
import { Row, Col, Layout, Typography, Descriptions, Avatar } from "antd";
import { useHistory } from "react-router-dom";

import { cfs } from "../../firebase/firebase";
import firebase from "firebase/app";
import { useParams } from "react-router-dom";

import { IEmployee } from "models/employee";

interface ParamTypes {
  idMerchant: string;
  employeeId: string;
}
const { Header, Content } = Layout;
const { Title } = Typography;

function EmployeeInfo() {
  let { idMerchant, employeeId } = useParams<ParamTypes>();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [employee, setEmployee] = useState<IEmployee>({
    employeeId: "",
    firstname: "",
    lastname: "",
    description: "",
    phone: "",
    imageUrl: "",
  });
  const [currentUser, setCurrentUser] = useState<any>({});

  useEffect(() => {
    if (idMerchant) {
      cfs
        .collection("merchants")
        .doc(`${idMerchant}`)
        .get()
        .then((doc: any) => {
          let tmpCurrentUser: any = doc.data();

          document.title = `${tmpCurrentUser.merchantNameTH} | ${tmpCurrentUser.merchantNameEN}`;
          setCurrentUser(tmpCurrentUser);

          // setProducts(pros);
        })
        .catch((e) => console.log(e));
    }
  }, [idMerchant]);

  useEffect(() => {
    if (idMerchant) {
      cfs
        .collection("merchants")
        .doc(`${idMerchant}`)
        .collection("employees")
        .doc(employeeId)
        .get()
        .then((doc: any) => {
          let tmpEmp: any = doc.data();
          setEmployee(tmpEmp);
          console.log(tmpEmp);
        })
        .catch((e) => console.log(e));
    }
  }, [idMerchant]);

  return (
    <Layout className="layout">
      <div>
        <Header
          style={{
            backgroundColor: "white",
            boxShadow: "0px 0px 20px 1px rgba(41,41,41,.25)",
            height: 65,
            position: "fixed",
            width: "100%",
            zIndex: 1,
          }}
        >
          <Row>
            <Col span={24} style={{ textAlign: "center", marginTop: 17 }}>
              <Title level={4}>ข้อมูลพนักงาน</Title>
            </Col>
          </Row>
        </Header>
      </div>
      <Content style={{ padding: "10px", marginTop: 80 }}>
        <div style={{ textAlign: "center" }}>
          <Avatar
            size={{ xs: 250, sm: 250, md: 250, lg: 250, xl: 250, xxl: 250 }}
            src={employee.imageUrl}
          />
          {/* <Title
            level={4}
            style={{ paddingTop: "12px" }}
          >{`รหัสพนักงาน ${employee.employeeId} `}</Title>
          <Title
            level={2}
            style={{ paddingTop: "0px" }}
          >{`${employee.firstname} ${employee.lastname}`}</Title>
          <Title
            level={4}
            style={{ paddingTop: "0px" }}
          >{`ชำนาญด้าน ${employee.description} `}</Title>
          <br />
          <br /> */}
        </div>
        <Descriptions
          title="ข้อมูลช่าง"
          column={1}
          layout={"horizontal"}
          // size={"small"}
          bordered
        >
          <Descriptions.Item label="รหัสพนักงาน">
            {`${employee.employeeId}`}
          </Descriptions.Item>
          <Descriptions.Item label="ชื่อ-สกุล">
            {`${employee.firstname} ${employee.lastname}`}
          </Descriptions.Item>
          <Descriptions.Item label="ชำนาญด้าน">
            {`${employee.description}`}
          </Descriptions.Item>
        </Descriptions>
      </Content>
      <div
        style={{
          boxShadow: "0px -1px 20px 2px rgba(41,41,41,.25)",
          width: "100%",
          height: 69,
          bottom: 0,
          position: "fixed",
          padding: 10,
          textAlign: "center",
        }}
      >
        <p style={{ margin: "revert" }}>ต้องการร้องเรียนติดต่อ 097-992-9428</p>
      </div>
    </Layout>
  );
}

export default EmployeeInfo;

import React, { useEffect, useState, useRef } from "react";
import { cfs } from "../../../firebase/firebase";
import {
  Col,
  Row,
  Table,
  Typography,
  Button,
  Switch,
  Modal,
  notification,
  Input,
  Space,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddCategoryModal from "./addCategoryModal";
import { CSVLink } from "react-csv";
import { sortFunction, groupBy } from "../../../utility/index";
import Highlighter from "react-highlight-words";

const { Title } = Typography;

const headers = [
  { label: "ลำดับ", key: "no" },
  { label: "ประเภทสินค้า", key: "category" },
  { label: "เปิดขาย", key: "active" },
];

const ProductCategoryTab = ({ currentUser }: any) => {
  const [
    visibleAddCategoryModal,
    setVisibleAddCategoryModal,
  ] = useState<boolean>(false);
  const [loadingCategory, setLoadingCategory] = useState<boolean>(true);
  const [category, setCetegory] = useState<any>([]);
  const searchInput = useRef<any>(null);
  const [error, setError] = useState<string>("");
  const [searchedColumn, setSearchedColumn] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`ค้นหา`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(
              e.target.value ? [e.target.value.toLowerCase()] : []
            )
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            ค้นหา
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            ล้าง
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase().trim())
        : "",
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => {
          searchInput.current.focus();
        }, 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: any = [
    {
      title: "ลำดับ",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 50,
      sorter: (a: any, b: any) => sortFunction(a, b, "no"),
    },
    ,
    {
      title: "ประเภทสินค้า",
      dataIndex: "category",
      key: "category",
      width: 200,
      ...getColumnSearchProps("category"),
      sorter: (a: any, b: any) => sortFunction(a, b, "category"),
    },
    {
      title: "เปิดขาย",
      align: "center",
      key: "active",
      dataIndex: "active",
      width: 150,
      filters: [
        {
          text: "เปิดขาย",
          value: true,
        },
        {
          text: "ปิดขาย",
          value: false,
        },
      ],
      onFilter: (value: any, record: any) => {
        return record.active === value;
      },
      render: (active: any, record: any) => (
        <Switch
          size="small"
          checked={active}
          onClick={() => handleChangeActive(record)}
        />
      ),
      sorter: (a: any, b: any) => sortFunction(a, b, "active"),
    },
  ];

  const handleChangeActive = (record: any) => {
    console.log("active : ", record);
    setLoadingCategory(true);
    cfs
      .collection("merchants")
      .doc(currentUser.merchantId)
      .collection("products")
      .where("category", "==", record.category)
      .get()
      .then(async (data) => {
        try {
          await Promise.all(
            data.docs.map(async (item: any) => {
              item.ref.update({
                active: !record.active,
              });

              return { ...item.data() };
            })
          );
          setError("");
          setLoadingCategory(false);
          // console.log(`update ${record.id} ${record.category}`);
        } catch (error) {
          setError(error.message);
          setLoadingCategory(false);
        }
      })
      .catch((error) => {
        console.log("error : ", error.message);
        setError(error.message);
        setLoadingCategory(false);
      });
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0] ? selectedKeys[0].trim() : "");
    setSearchedColumn(dataIndex ? dataIndex.trim() : "");
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    if (error) {
      notification.error({
        message: "เกิดข้อผิดพลาด",
        description: error,
        placement: "bottomRight",
        onClick: () => {
          setError("");
        },
      });
    }
  }, [error]);

  useEffect(() => {
    if (!currentUser.merchantId) return;
    setLoadingCategory(true);
    let unsubscribe = cfs
      .collection("/merchants")
      .doc(`${currentUser.merchantId}`)
      .collection("products")
      .onSnapshot({
        next: async (snapshot) => {
          if (snapshot.size) {
            let tmpProducts = await Promise.all(
              snapshot.docs.map(async (item) => {
                let tmp = await item.data();
                return { ...tmp };
              })
            );
            let tmpCategory = Object.keys(groupBy(tmpProducts, "category")).map(
              (category) => {
                let tmpFilter = tmpProducts.filter(
                  (i) =>
                    i.category === category &&
                    (i.active === true || i.active === undefined)
                );

                return {
                  category: category,
                  active: tmpFilter.length > 0,
                };
              }
            );

            tmpCategory = tmpCategory.sort((a: any, b: any) =>
              sortFunction(a, b, "category")
            );

            tmpCategory = tmpCategory.map((item, index) => ({
              ...item,
              no: index + 1,
            }));

            // console.log("tmpCategory :>> ", tmpCategory);

            setCetegory(tmpCategory);
            setLoadingCategory(false);
          } else {
            // it's empty
            setLoadingCategory(false);
            setCetegory([]);
          }
        },
        error: (error) => {
          console.log(error);
          setError(error.message);
          setLoadingCategory(false);
        },
      });

    return () => {
      unsubscribe();
    };
  }, [currentUser.merchantId]);

  return (
    <div>
      <Table
        bordered
        loading={loadingCategory}
        title={() => (
          <Row
            justify="space-between"
            //  justify="end"
          >
            <Col>
              <Title level={5}>ตารางรายการประเภทสินค้า</Title>
            </Col>
            <Col>
              <CSVLink
                data={category}
                headers={headers}
                filename={"ตารางรายการประเภทสินค้า.csv"}
                className="ant-btn ant-btn-link"
                target="_blank"
              >
                บันทึกเป็น CSV
              </CSVLink>
              {/* <Button
                // disabled
                type="primary"
                onClick={() => setVisibleAddCategoryModal(true)}
              >
                เพิ่มประเภทสินค้า
              </Button> */}
            </Col>
          </Row>
        )}
        columns={columns}
        dataSource={category}
        rowKey="no"
        pagination={false}
        scroll={{ y: "100%", x: "max-content" }}
      />
      {visibleAddCategoryModal && (
        <AddCategoryModal
          visible={visibleAddCategoryModal}
          setVisible={setVisibleAddCategoryModal}
          currentUser={currentUser}
          category={category}
        />
      )}
    </div>
  );
};

export default ProductCategoryTab;

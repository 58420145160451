import React, { useState, useEffect } from "react";
import { Spin, notification, Tabs } from "antd";
import ProductTab from "./productTab";
import EmployeeTab from "./employeeTab";
import ProductCategoryTab from "./productCategoryTab";
import ChoiceTab from "./choiceTab";
import CarouselTab from "./carouselTab";

const { TabPane } = Tabs;
const Product = ({ currentUser }: any) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const callback = (key: any) => {
    // console.log(key);
  };

  useEffect(() => {
    if (currentUser.merchantNameTH) {
      document.title = `${currentUser.merchantNameTH} | ${currentUser.merchantNameEN}`;
      setLoading(false);
    }
  }, [currentUser.merchantNameTH]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: "เกิดข้อผิดพลาด",
        description: error,
        placement: "bottomRight",
        onClick: () => {
          setError("");
        },
        onClose: () => {
          setError("");
        },
      });
    }
  }, [error]);

  return (
    <Spin tip="กำลังโหลดข้อมูล.." spinning={loading}>
      <div>
        <div className="site-card-wrapper">
          <Tabs onChange={callback} type="card" tabPosition="top">
            <TabPane tab="สินค้า" key="1">
              <ProductTab currentUser={currentUser} />
            </TabPane>
            <TabPane tab="ประเภทสินค้า" key="2">
              <ProductCategoryTab currentUser={currentUser} />
            </TabPane>
            <TabPane tab="ตัวเลือกเสริม" key="3">
              <ChoiceTab currentUser={currentUser} />
            </TabPane>
            <TabPane tab="พนักงาน" key="4">
              <EmployeeTab currentUser={currentUser} />
            </TabPane>
            <TabPane tab="Carsousel" key="5">
              <CarouselTab currentUser={currentUser} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Spin>
  );
};

export default Product;
